import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import useErrorHandler from "../hooks/useErrorHandler";
import { login } from "../apis/authorizationTokenApis";
import { authorizationTokenState } from "../recoil/atoms";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const [loadingLogIn, setLoadingLogIn] = useState(false);

  const setAuthorizationToken = useSetRecoilState(authorizationTokenState);

  const handleError = useErrorHandler();

  const { t } = useTranslation();

  const onFinish = async (values) => {
    const body = {
      username: values.username,
      password: values.password,
    };

    setLoadingLogIn(true);
    try {
      const response = await login(body);
      setAuthorizationToken(response.data);
    } catch (error) {
      handleError(error);
      setLoadingLogIn(false);
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col
        style={{
          marginTop: "128px",
          width: "100%",
          maxWidth: "440px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Form
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Typography.Title level={3}>{t("Login")}</Typography.Title>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: t("Please input value!", { value: t("Username") }),
              },
              {
                min: 4,
                message: t("Minimum value characters!", { value: 4 }),
              },
              {
                max: 20,
                message: t("Maximum value characters!", { value: 20 }),
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder={t("Username")} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("Please input value!", { value: t("Password") }),
              },
              {
                min: 6,
                message: t("Minimum value characters!", { value: 6 }),
              },
              {
                max: 20,
                message: t("Maximum value characters!", { value: 20 }),
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={t("Password")}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loadingLogIn}
            >
              {t("Log In")}
            </Button>
          </Form.Item>
          <Link to="/reset-password">{t("Forgot password?")}</Link>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginPage;
