import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  message,
} from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  getTrendyolYemekIntegrationSettings,
  saveTrendyolYemekIntegrationSettings,
} from "../../apis/trendyolYemekIntegrationSettingsApi";
import useErrorHandler from "../../hooks/useErrorHandler";

const TrendyolYemekIntegrationPage = () => {
  const [
    loadingGetTrendyolYemekIntegrationSettings,
    setLoadingGetTrendyolYemekIntegrationSettings,
  ] = useState(true);
  const [
    loadingSaveTrendyolYemekIntegrationSettings,
    setLoadingSaveTrendyolYemekIntegrationSettings,
  ] = useState(false);

  const [
    trendyolYemekIntegrationSettings,
    setTrendyolYemekIntegrationSettings,
  ] = useState({});

  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchTrendyolYemekIntegrationSettings = async () => {
      try {
        const response = await getTrendyolYemekIntegrationSettings();
        setTrendyolYemekIntegrationSettings(response.data);
      } catch (error) {
        if (error.response.status !== 404) {
          handleError(error);
        }
      }
      setLoadingGetTrendyolYemekIntegrationSettings(false);
    };
    fetchTrendyolYemekIntegrationSettings();
  }, []);

  const onFinish = async (values) => {
    setLoadingSaveTrendyolYemekIntegrationSettings(true);
    const body = {
      sellerId: values.sellerId,
      apiKey: values.apiKey,
      apiSecret: values.apiSecret,
    };
    try {
      await saveTrendyolYemekIntegrationSettings(body);
      message.success(
        t("Value saved.", { value: t("Trendyol Yemek Integration Settings") })
      );
    } catch (error) {
      handleError(error);
    }
    setLoadingSaveTrendyolYemekIntegrationSettings(false);
  };

  return (
    !loadingGetTrendyolYemekIntegrationSettings && (
      <Form layout="vertical" onFinish={onFinish}>
        <Card
          size="small"
          title={t("Value Update Form", { value: t("Table") })}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                initialValue={trendyolYemekIntegrationSettings.sellerId}
                name="sellerId"
                label="Satıcı ID"
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: "Satıcı ID",
                    }),
                    type: "number",
                  },
                  {
                    min: 1,
                    message: t("Minimum value!", { value: 1 }),
                    type: "number",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                initialValue={trendyolYemekIntegrationSettings.apiKey}
                name="apiKey"
                label="API Key"
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", { value: "API Key" }),
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                initialValue={trendyolYemekIntegrationSettings.apiSecret}
                name="apiSecret"
                label="API Secret"
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", { value: "API Secret" }),
                  },
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingSaveTrendyolYemekIntegrationSettings}
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Card>
      </Form>
    )
  );
};

export default TrendyolYemekIntegrationPage;
