import { Card, Col, Descriptions, Row, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { getTenant } from "../../apis/tenantApis";
import UserCreateForm from "../../components/user/UserCreateForm";
import { isNumber } from "../../common/CommonUtils";

const UserCreatePage = () => {
  const { tenantId } = useParams();

  const [tenant, setTenant] = useState({});

  const [loadingGetTenant, setLoadingGetTenant] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const response = await getTenant(tenantId);
        setTenant(response.data);
        setLoadingGetTenant(false);
      } catch (error) {
        handleError(error);
        navigate("/tenants/list");
      }
    };

    if (!tenantId || isNumber(tenantId)) {
      fetchTenant();
    } else {
      message.error("Not valid id");
      navigate("/tenants/list");
    }
  }, []);

  return (
    !loadingGetTenant && (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card size="small" title="Tenant Info">
            <Descriptions>
              <Descriptions.Item label="Tenant Name">
                {tenant.name}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24}>
          <UserCreateForm tenantId={tenantId} />
        </Col>
      </Row>
    )
  );
};

export default UserCreatePage;
