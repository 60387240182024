import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { getProduct, updateProduct } from "../../apis/productApis";
import { searchListCategories } from "../../apis/categoryApis";
import { isNumber } from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";
import UploadImage from "../../components/common/UploadImage";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";

const ProductUpdatePage = () => {
  const { productId } = useParams();

  const [form] = Form.useForm();

  const [product, setProduct] = useState({});
  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);

  const [categories, setCategories] = useState([]);

  const [loadingGetProduct, setLoadingGetProduct] = useState(true);
  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingUpdateProduct, setLoadingUpdateProduct] = useState(false);

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const categoryMap = new Map();
  const parentCategoryIdAndSubCategoriesMap = new Map();
  parentCategoryIdAndSubCategoriesMap.set(null, []);
  categories.forEach((category) => {
    categoryMap.set(category.id, category.name);

    if (
      parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId) ===
      undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        category,
      ]);
    } else {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        ...parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId),
        category,
      ]);
    }

    if (
      category.parentCategoryId === null &&
      parentCategoryIdAndSubCategoriesMap.get(category.id) === undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.id, []);
    }
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getProduct(productId);
        setProduct(response.data);
        setLoadingGetProduct(false);
      } catch (error) {
        handleError(error);
        navigate("/products/list");
      }
    };

    if (isNumber(productId)) {
      fetchProduct();
    } else {
      message.error("Not valid id");
      navigate("/products/list");
    }
  }, []);

  useEffect(() => {
    if (product.id) {
      const fetchCategories = async () => {
        try {
          const response = await searchListCategories();
          setCategories(response.data);
        } catch (error) {
          handleError(error);
        }
        setLoadingSearchListCategories(false);
      };

      fetchCategories();
    }
  }, [product]);

  const onFinish = async (values) => {
    const body = {
      categoryId: values.categoryId,
      name: values.productName,
      price: values.price,
      position: values.position,
      status: values.status,
      description: values.description ? values.description : null,
      image: image ? image.split("base64,")[1] : null,
      showInQrMenu: values.showInQrMenu,
    };
    setLoadingUpdateProduct(true);
    try {
      await updateProduct(product.id, body);
      message.success(t("Value updated.", { value: t("Product") }));
      navigate("/products/list");
    } catch (error) {
      handleError(error);
      setLoadingUpdateProduct(false);
    }
  };

  return (
    !loadingGetProduct && (
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Card
          size="small"
          title={t("Value Update Form", { value: t("Product") })}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="categoryId"
                label={t("Category")}
                initialValue={product.categoryId}
                rules={[
                  {
                    required: true,
                    message: t("Please select value!", {
                      value: t("Category"),
                    }),
                  },
                ]}
              >
                <Select
                  loading={loadingSearchListCategories}
                  options={parentCategoryIdAndSubCategoriesMap
                    .get(null)
                    .map((parentCategory) => {
                      return {
                        label: parentCategory.name,
                        options: parentCategoryIdAndSubCategoriesMap
                          .get(parentCategory.id)
                          .map((subCategory) => ({
                            label: subCategory.name,
                            value: subCategory.id,
                          })),
                      };
                    })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="productName"
                label={t("Value Name", { value: t("Product") })}
                initialValue={product.name}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Value Name", { value: t("Product") }),
                    }),
                  },
                  {
                    min: 2,
                    message: t("Minimum value characters!", { value: 2 }),
                  },
                  {
                    max: 40,
                    message: t("Maximum value characters!", { value: 40 }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="price"
                label={t("Product Price")}
                initialValue={product.price}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Product Price"),
                    }),
                    type: "number",
                  },
                  {
                    min: 0,
                    message: t("Minimum value!", { value: 0 }),
                    type: "number",
                  },
                  {
                    max: 9999.99,
                    message: t("Maximum value!", { value: 9999.99 }),
                    type: "number",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  addonAfter="TL"
                  type="number"
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="position"
                label={t("Value Position", { value: t("Product") })}
                initialValue={product.position}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Value Position", { value: t("Product") }),
                    }),
                  },
                  {
                    type: "number",
                    min: 0,
                    message: t("Minimum value!", { value: 0 }),
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="description"
                label={t("Description")}
                initialValue={product.description}
              >
                <Input.TextArea maxLength={250} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label={t("Image")}>
                <UploadImage
                  setLoading={setLoadingImage}
                  setImage={setImage}
                  {...(product.imageFileName
                    ? {
                        fileName: product.name,
                        src: `/images/tenants/${authorizationToken.tenant.id}/products/${product.imageFileName}`,
                      }
                    : {})}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                name="status"
                label={t("Status")}
                initialValue={product.status}
                rules={[
                  {
                    required: true,
                    message: t("Please select value!", { value: "Status" }),
                  },
                ]}
              >
                <Select>
                  <Select.Option value="ACTIVE">{t("Active")}</Select.Option>
                  <Select.Option value="PASSIVE">{t("Passive")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="showInQrMenu"
                valuePropName="checked"
                initialValue={product.showInQrMenu}
              >
                <Checkbox>{t("Show in QR menu")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                loadingSearchListCategories ||
                loadingImage ||
                loadingUpdateProduct
              }
              disabled={product.status === "DELETED"}
            >
              {t("Update")}
            </Button>
          </Form.Item>
        </Card>
      </Form>
    )
  );
};

export default ProductUpdatePage;
