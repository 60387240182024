import { message } from "antd";
import { useSetRecoilState } from "recoil";
import { authorizationTokenState } from "../recoil/atoms";
import { useTranslation } from "react-i18next";

const useErrorHandler = () => {
  const setAuthorizationToken = useSetRecoilState(authorizationTokenState);
  const { t } = useTranslation();

  return (error) => {
    let errorMessage = t(
      "Something went wrong. Please contact with developer."
    );

    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        setAuthorizationToken(null);
      }

      if (error.response.data) {
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      }
    }

    message.error(errorMessage);
  };
};

export default useErrorHandler;
