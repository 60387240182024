import { useNavigate, useParams } from "react-router-dom";
import { getOrder } from "../../apis/orderApis";
import OrderContent from "../../components/order/OrderContent";
import { searchListOrderProducts } from "../../apis/orderProductApis";
import { searchListPayments } from "../../apis/paymentApis";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { isNumber } from "../../common/CommonUtils";
import { message } from "antd";
import { getBillPrintSettings } from "../../apis/billPrintSettingsApis";

const OrderPage = () => {
  const { orderId } = useParams();

  // const [tables, setTables] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [products, setProducts] = useState([]);
  const [order, setOrder] = useState({});
  const [orderProducts, setOrderProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [billPrintSettings, setBillPrintSettings] = useState({});

  // const [loadingSearchListTables, setLoadingSearchListTables] = useState(true);
  // const [loadingSearchListCategories, setLoadingSearchListCategories] =
  //   useState(true);
  // const [loadingSearchListProducts, setLoadingSearchListProducts] =
  //   useState(true);
  const [loadingGetOrder, setLoadingGetOrder] = useState(true);
  const [loadingSearchListOrderProducts, setLoadingSearchListOrderProducts] =
    useState(true);
  const [loadingSearchListPayments, setLoadingSearchListPayments] =
    useState(true);
  const [loadingGetBillPrintSettings, setLoadingGetBillPrintSettings] =
    useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const readyToRender =
    // !loadingSearchListTables &&
    // !loadingSearchListCategories &&
    // !loadingSearchListProducts &&
    !loadingGetBillPrintSettings &&
    !loadingGetOrder &&
    !loadingSearchListOrderProducts &&
    !loadingSearchListPayments;

  useEffect(() => {
    // const fetchTables = async () => {
    //   const tableSearchDTO = {
    //     statusList: ["ACTIVE"],
    //   };
    //   try {
    //     const response = await searchListTables(tableSearchDTO);
    //     setTables(response.data);
    //   } catch (error) {
    //     handleError(error);
    //   }
    //   setLoadingSearchListTables(false);
    // };

    // const fetchCategories = async () => {
    //   const categorySearchDTO = {
    //     statusList: ["ACTIVE"],
    //   };
    //   try {
    //     const response = await searchListCategories(categorySearchDTO);
    //     setCategories(response.data);
    //   } catch (error) {
    //     handleError(error);
    //   }
    //   setLoadingSearchListCategories(false);
    // };

    // const fetchProducts = async () => {
    //   const productSearchDTO = {
    //     statusList: ["ACTIVE"],
    //   };
    //   try {
    //     const response = await searchListProducts(productSearchDTO);
    //     setProducts(response.data);
    //   } catch (error) {
    //     handleError(error);
    //   }
    //   setLoadingSearchListProducts(false);
    // };

    const fetchOrder = async () => {
      try {
        const response = await getOrder(orderId);
        setOrder(response.data);
      } catch (error) {
        handleError(error);
        navigate("/orders/list");
      }
      setLoadingGetOrder(false);
    };

    const fetchOrderProductsByOrderId = async () => {
      const orderProductSearchDTO = {
        orderIdList: [orderId],
      };
      try {
        const response = await searchListOrderProducts(orderProductSearchDTO);
        setOrderProducts(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListOrderProducts(false);
    };

    const fetchPaymentsByOrderId = async () => {
      const paymentSearchDTO = {
        orderIdList: [orderId],
      };
      try {
        const response = await searchListPayments(paymentSearchDTO);
        setPayments(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListPayments(false);
    };

    const fetchBillPrintSettings = async () => {
      try {
        const response = await getBillPrintSettings();
        setBillPrintSettings(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetBillPrintSettings(false);
    };

    // fetchTables();
    // fetchCategories();
    // fetchProducts();

    if (isNumber(orderId)) {
      fetchOrder();
      fetchOrderProductsByOrderId();
      fetchPaymentsByOrderId();
      fetchBillPrintSettings();
    } else {
      message.error("Not valid id");
      navigate("/orders/list");
    }
  }, []);

  return (
    readyToRender && (
      <OrderContent
        readOnly={true}
        emptyTables={[]}
        categories={[]}
        products={[]}
        order={order}
        orderProducts={orderProducts}
        payments={payments}
        setOrders={() => {}}
        setOrderProducts={() => {}}
        setPayments={() => {}}
        billPrintSettings={billPrintSettings}
      />
    )
  );
};

export default OrderPage;
