import { Loading3QuartersOutlined } from "@ant-design/icons";
import { formatTL } from "../../common/CommonUtils";
import useErrorHandler from "../../hooks/useErrorHandler";
import { createOrderProduct } from "../../apis/orderProductApis";
import { useState } from "react";
import { Card } from "primereact/card";
import { BlockUI } from "primereact/blockui";
import SolidDivider from "../common/SolidDivider";

const ProductCard = (props) => {
  const { order, product, setOrderProducts } = props;

  const [loadingCreateOrderProduct, setLoadingCreateOrderProduct] =
    useState(false);

  const handleError = useErrorHandler();

  const onClickProductCard = async (product) => {
    const body = {
      productId: product.id,
    };
    setLoadingCreateOrderProduct(true);
    try {
      const response = await createOrderProduct(order.id, body);
      setOrderProducts((prevState) => [...prevState, response.data]);
    } catch (error) {
      handleError(error);
    }
    setLoadingCreateOrderProduct(false);
  };

  return (
    <BlockUI
      pt={{ root: { className: "h-full" } }}
      blocked={loadingCreateOrderProduct}
      template={<i className="pi pi-spin pi-spinner text-4xl" />}
    >
      <Card
        className="h-full bg-blue-100 cursor-pointer"
        pt={{
          title: { className: "p-1 text-base font-semibold" },
          body: { className: "p-1" },
          content: { className: "p-1" },
        }}
        title={
          <div
            className="text-overflow-ellipsis overflow-hidden"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              height: "2lh",
            }}
          >
            {product.name}
          </div>
        }
        onClick={() => {
          onClickProductCard(product);
        }}
      >
        {formatTL(product.price)}
      </Card>
    </BlockUI>
  );
};

export default ProductCard;
