import { message } from "antd";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { createPayment } from "../../apis/paymentApis";
import PaymentsTable from "./PaymentsTable";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { validateInputNumber } from "../../common/FormValidationUtils";
import { useFormik } from "formik";
import FormWrapper from "../common/FormWrapper";

const PaymentsTabContent = (props) => {
  const { readOnly, order, payments, setPayments } = props;

  const [loadingCreatePayment, setLoadingCreatePayment] = useState(false);

  const handleError = useErrorHandler();

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      paymentType: "CASH",
      paymentAmount: null,
    },
    validate: (data) => {
      let errors = {};

      validateInputNumber(
        "paymentAmount",
        data.paymentAmount,
        true,
        0,
        9999.99,
        errors
      );

      return errors;
    },
    onSubmit: async (data) => {
      const body = {
        type: data.paymentType,
        amount: data.paymentAmount,
      };

      setLoadingCreatePayment(true);
      try {
        const response = await createPayment(order.id, body);
        formik.resetForm();

        setPayments((prevState) => [...prevState, response.data]);
        message.success(t("Value created.", { value: t("Payment") }));
      } catch (error) {
        handleError(error);
      }
      setLoadingCreatePayment(false);
    },
  });

  return (
    <div className="grid w-full">
      {!readOnly && (
        <div className="col-12">
          <FormWrapper title={t("Value Create Form", { value: t("Payment") })}>
            <form className="flex flex-column" onSubmit={formik.handleSubmit}>
              <div className="field flex flex-column">
                <label>{t("Value Type", { value: t("Payment") })}*</label>
                <SelectButton
                  allowEmpty={false}
                  name="paymentType"
                  value={formik.values.paymentType}
                  onChange={formik.handleChange}
                  options={[
                    {
                      value: "CASH",
                      label: (
                        <>
                          <i className="pi pi-turkish-lira" /> {t("Cash")}
                        </>
                      ),
                    },
                    {
                      value: "CREDIT_CARD",
                      label: (
                        <>
                          <i className="pi pi-credit-card" /> {t("Credit Card")}
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="field flex flex-column">
                <label>{t("Value Amount", { value: t("Payment") })}*</label>
                <InputNumber
                  name="paymentAmount"
                  mode="decimal"
                  min={0}
                  maxFractionDigits={2}
                  value={formik.values.paymentAmount}
                  onChange={(e) => {
                    formik.setFieldValue("paymentAmount", e.value);
                  }}
                  invalid={formik.errors.paymentAmount}
                />
                {formik.errors.paymentAmount && (
                  <small className="p-error">
                    {formik.errors.paymentAmount}
                  </small>
                )}
              </div>
              <Button
                type="submit"
                label={t("Create")}
                loading={loadingCreatePayment}
              />
            </form>
          </FormWrapper>
        </div>
      )}
      <div className="col-12">
        <PaymentsTable
          order={order}
          payments={payments}
          setPayments={setPayments}
        />
      </div>
    </div>
  );
};

export default PaymentsTabContent;
