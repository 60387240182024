import axios from "axios";
import { Buffer } from "buffer";

export const getImageAsBase64 = (imageUrl) => {
  return axios
    .get(imageUrl, {
      responseType: "arraybuffer",
    })
    .then(
      (response) =>
        `data:image/*;base64,${Buffer.from(response.data, "binary").toString(
          "base64"
        )}`
    );
};
