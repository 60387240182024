import { changeLanguage, t } from "i18next";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authorizationTokenState, languageState } from "../recoil/atoms";
import useErrorHandler from "../hooks/useErrorHandler";
import { logout } from "../apis/authorizationTokenApis";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { Avatar } from "primereact/avatar";
import { PanelMenu } from "primereact/panelmenu";
import { Sidebar } from "primereact/sidebar";
import { BlockUI } from "primereact/blockui";

const ContentWrapper = (props) => {
  const navigate = useNavigate();

  const languageSelectorRef = useRef(null);

  const [loadingLogout, setLoadingLogout] = useState(false);
  const [sidebarMenuVisible, setSidebarMenuVisible] = useState(false);

  const [authorizationToken, setAuthorizationToken] = useRecoilState(
    authorizationTokenState
  );

  const setLanguage = useSetRecoilState(languageState);

  const handleError = useErrorHandler();

  const superAdminMenuItems = [
    {
      label: "Tenants",
      items: [
        {
          label: "List Tenants",
          command: () => {
            navigate("/tenants/list");
          },
        },
        {
          label: "Create Tenant",
          command: () => {
            navigate("/tenants/create");
          },
        },
      ],
    },
  ];

  const tenantAdminMenuItems = [
    {
      label: t("Order Management"),
      icon: "pi pi-receipt",
      command: () => {
        navigate("/order-management");
      },
    },
    {
      label: t("Statistics"),
      icon: "pi pi-chart-line",
      command: () => {
        navigate("/statistics");
      },
    },
    {
      label: t("Business"),
      icon: "pi pi-warehouse",
      items: [
        {
          label: t("Order"),
          icon: "pi pi-receipt",
          items: [
            {
              label: t("List Value", { value: t("Order") }),
              command: () => {
                navigate("/orders/list");
              },
            },
          ],
        },
        {
          label: t("Product"),
          icon: "pi pi-box",
          items: [
            {
              label: t("List Value", { value: t("Product") }),
              command: () => {
                navigate("/products/list");
              },
            },
            {
              label: t("Create Value", { value: t("Product") }),
              command: () => {
                navigate("/products/create");
              },
            },
          ],
        },
        {
          label: t("Category"),
          icon: "pi pi-th-large",
          items: [
            {
              label: t("List Value", { value: t("Category") }),
              command: () => {
                navigate("/categories/list");
              },
            },
            {
              label: t("Create Value", { value: t("Category") }),
              command: () => {
                navigate("/categories/create");
              },
            },
          ],
        },
        {
          label: t("Table"),
          icon: "pi pi-ticket",
          items: [
            {
              label: t("List Value", { value: t("Table") }),
              command: () => {
                navigate("/tables/list");
              },
            },
            {
              label: t("Create Value", { value: t("Table") }),
              command: () => {
                navigate("/tables/create");
              },
            },
          ],
        },
        {
          label: t("User"),
          icon: "pi pi-users",
          items: [
            {
              label: t("List Value", { value: t("User") }),
              command: () => {
                navigate("/users/list");
              },
            },
            {
              label: t("Create Value", { value: t("User") }),
              command: () => {
                navigate("/users/create");
              },
            },
          ],
        },
        {
          label: t("Bill Print Settings"),
          icon: "pi pi-cog",
          command: () => {
            navigate("/bill-print-settings");
          },
        },
        {
          label: t("Qr Menu Settings"),
          icon: "pi pi-cog",
          command: () => {
            navigate("/qr-menu-settings");
          },
        },
      ],
    },
    {
      label: t("Integrations"),
      icon: "pi pi-cog",
      items: [
        {
          label: "Trendyol Yemek",
          command: () => {
            navigate("/integrations/trendyol-yemek");
          },
        },
      ],
    },
    {
      label: t("Balance Management"),
      icon: "pi pi-turkish-lira",
      command: () => {
        navigate("/balance-management");
      },
    },
  ];

  const items = [
    {
      template: () => {
        return (
          <Image
            className="flex justify-content-center"
            src="/images/logo/logo.png"
            alt="logo"
            width="140"
          />
        );
      },
    },
    ...(authorizationToken
      ? [
          ...(authorizationToken.user.role === "SUPER_ADMIN"
            ? superAdminMenuItems
            : authorizationToken.user.role === "TENANT_ADMIN"
            ? tenantAdminMenuItems
            : []),
          {
            label: t("Log Out"),
            icon: "pi pi-sign-out",
            command: () => {
              onClickLogout();
            },
          },
        ]
      : []),
    {
      template: () => {
        return (
          <span className="flex flex-row px-2">
            <Avatar
              label={
                authorizationToken
                  ? Array.from(authorizationToken.user.firstName)[0] +
                    Array.from(authorizationToken.user.lastName)[0]
                  : "?"
              }
              className="mr-2 my-1"
              shape="circle"
              size="large"
            />
            <div className="flex flex-column justify-content-center">
              <span className="font-bold">
                {authorizationToken
                  ? authorizationToken.user.firstName +
                    " " +
                    authorizationToken.user.lastName
                  : "..."}
              </span>
              <span className="text-sm">
                {authorizationToken ? authorizationToken.tenant.name : "..."}
              </span>
            </div>
          </span>
        );
      },
    },
    {
      template: () => {
        return (
          <span className="flex justify-content-end px-2">
            <Menu
              popup
              ref={languageSelectorRef}
              model={[
                {
                  label: "Türkçe",
                  command: (e) => {
                    changeLanguage("tr");
                    localStorage.setItem("language", "tr");
                    setLanguage("tr");
                    languageSelectorRef.current.toggle(e);
                  },
                },
                {
                  label: "English",
                  command: (e) => {
                    changeLanguage("en");
                    localStorage.setItem("language", "en");
                    setLanguage("en");
                    languageSelectorRef.current.toggle(e);
                  },
                },
              ]}
            />
            <Button
              icon="pi pi-language"
              rounded
              aria-label="Language"
              onClick={(e) => languageSelectorRef.current.toggle(e)}
            />
          </span>
        );
      },
    },
  ];

  const onClickLogout = async () => {
    setLoadingLogout(true);
    try {
      await logout();
      setAuthorizationToken(null);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setAuthorizationToken(null);
      } else {
        handleError(error);
      }
    }

    setLoadingLogout(false);
  };

  const sidebarMenu = (
    <BlockUI
      blocked={loadingLogout}
      fullScreen
      template={<i className="pi pi-lock" style={{ fontSize: "3rem" }}></i>}
    >
      <PanelMenu
        className="overflow-y-auto"
        style={{ width: 240 }}
        model={items}
      />
    </BlockUI>
  );

  return (
    <div className="flex flex-row">
      <Sidebar
        header={false}
        style={{ width: 240 }}
        visible={sidebarMenuVisible}
        onHide={() => {
          setSidebarMenuVisible(false);
        }}
        showCloseIcon={false}
        content={sidebarMenu}
      />
      <div className="hidden lg:block">{sidebarMenu}</div>
      <div className="flex-column w-full">
        <div className="block lg:hidden">
          <Button
            outlined
            className="mx-2 my-1"
            icon="pi pi-bars"
            aria-label="Open Menu"
            onClick={() => {
              setSidebarMenuVisible(true);
            }}
          />
        </div>
        <div className="w-full px-2">{props.children}</div>
      </div>
    </div>
  );
};

export default ContentWrapper;
