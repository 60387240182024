import { Button, Card, Col, Form, Input, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchUsers } from "../../apis/userApis";
import { validateAndGetTextParam } from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";

const UserList = (props) => {
  const { tenantId } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [userPage, setUserPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchUsers, setLoadingSearchUsers] = useState(false);

  const handleError = useErrorHandler();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const pageParam = searchParams.get("page");
  const sizeParam = searchParams.get("size");
  const firstNameParam = searchParams.get("firstName");
  const lastNameParam = searchParams.get("lastName");
  const firstName = validateAndGetTextParam(firstNameParam, 40);
  const lastName = validateAndGetTextParam(lastNameParam, 40);

  useEffect(() => {
    const fetchUsers = async () => {
      const userSearchDTO = {
        firstName,
        lastName,
      };
      setLoadingSearchUsers(true);
      try {
        const response = await searchUsers(
          pageParam - 1,
          sizeParam,
          userSearchDTO,
          tenantId
        );
        setUserPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchUsers(false);
    };

    fetchUsers();
  }, [pageParam, sizeParam, firstNameParam, lastNameParam, retriggerValue]);

  const onFinish = (values) => {
    if (values.firstName) {
      searchParams.set("firstName", values.firstName);
    } else {
      searchParams.delete("firstName");
    }

    if (values.lastName) {
      searchParams.set("lastName", values.lastName);
    } else {
      searchParams.delete("lastName");
    }

    searchParams.set("page", 1);

    setSearchParams(searchParams);

    setRetriggerValue((prevState) => prevState + 1);
  };

  const columns = [
    {
      title: t("First Name"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("Last Name"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: t("Username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("Contact Email"),
      dataIndex: "contactEmail",
      key: "contactEmail",
    },
    {
      title: t("Contact Number"),
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card
          size="small"
          title={t("Value Search Filter Form", { value: t("User") })}
        >
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="firstName"
                  label={t("First Name")}
                  initialValue={firstName}
                  rules={[
                    {
                      max: 40,
                      message: t("Maximum value characters!", { value: 40 }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="lastName"
                  label={t("Last Name")}
                  initialValue={lastName}
                  rules={[
                    {
                      max: 40,
                      message: t("Maximum value characters!", { value: 40 }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Row gutter={16}>
                    <Col style={{ flexGrow: 1 }}></Col>
                    <Col>
                      <Button
                        disabled={loadingSearchUsers}
                        onClick={() => {
                          form.setFieldValue("firstName", undefined);
                          form.setFieldValue("lastName", undefined);
                        }}
                      >
                        {t("Reset")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingSearchUsers}
                      >
                        {t("Filter")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col xs={24}>
        <div>
          <Table
            scroll={{ x: "100%" }}
            bordered
            columns={columns}
            dataSource={userPage.content}
            pagination={{
              showSizeChanger: true,
              current: userPage.number + 1,
              pageSize: userPage.size,
              total: userPage.totalElements,
            }}
            rowKey="id"
            onChange={(pagination, filters, sorter) => {
              searchParams.set("page", pagination.current);
              searchParams.set("size", pagination.pageSize);
              setSearchParams(searchParams);
            }}
            loading={loadingSearchUsers}
          />
        </div>
      </Col>
    </Row>
  );
};

export default UserList;
