import { t } from "i18next";

export const validateInputText = (
  name,
  value,
  required,
  minSize,
  maxSize,
  errors
) => {
  if (required && !value) {
    errors[name] = t("Must not be empty!");
  } else if (minSize && value.length < minSize) {
    errors[name] = t("Minimum value characters!", { value: minSize });
  } else if (maxSize && value.length > maxSize) {
    errors[name] = t("Maximum value characters!", { value: maxSize });
  }
};

export const validateInputNumber = (
  name,
  value,
  required,
  minValue,
  maxValue,
  errors
) => {
  if (required && value !== 0 && !value) {
    errors[name] = t("Must not be empty!");
  } else if (minValue && value < minValue) {
    errors[name] = t("Minimum value!", { value: minValue });
  } else if (maxValue && value > maxValue) {
    errors[name] = t("Maximum value!", { value: maxValue });
  }
};
