import { ConfigProvider, Divider, Table } from "antd";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import { t } from "i18next";
import dayjs from "dayjs";

const TrendyolYemekBill = (props) => {
  const { trendyolYemekPackage, printable, billPrintSettings } = props;

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const columns = [
    {
      width: "100%",
      render: (_, line) => line.name,
    },
    {
      render: (_, line) => ({
        ...(printable
          ? {
              props: {
                style: {
                  borderRight: "1px solid",
                },
              },
            }
          : {}),
        children: (
          <div
            style={{ whiteSpace: "nowrap" }}
          >{`${line.items.length} X ${line.price}`}</div>
        ),
      }),
    },
    {
      align: "right",
      render: (_, line) => line.items.length * line.price,
    },
  ];

  const columnsModifierProducts = [
    {
      width: "100%",
      render: (_, modifierProduct) =>
        t("Additional") + ": " + modifierProduct.name,
    },
    {
      render: (_, modifierProduct) => ({
        ...(printable
          ? {
              props: {
                style: {
                  borderRight: "1px solid",
                },
              },
            }
          : {}),
        children: (
          <div
            style={{ whiteSpace: "nowrap" }}
          >{`${modifierProduct.count} X ${modifierProduct.price}`}</div>
        ),
      }),
    },
    {
      align: "right",
      render: (_, modifierProduct) =>
        modifierProduct.count * modifierProduct.price,
    },
  ];

  const columnsExtraIngredients = [
    {
      width: "100%",
      render: (_, extraIngredient) =>
        t("To be added") + ": " + extraIngredient.name,
    },
    {
      align: "right",
      render: (_, extraIngredient) => extraIngredient.price,
    },
  ];

  const columnsRemovedIngredients = [
    {
      width: "100%",
      render: (_, removedIngredient) => <s>{removedIngredient.name}</s>,
    },
  ];

  const getModifierProductsTable = (modifierProducts, count) => {
    return (
      modifierProducts.length > 0 && (
        <Table
          scroll={{ x: "100%" }}
          size="small"
          rowKey="modifierGroupId"
          bordered={!printable}
          showHeader={false}
          columns={columnsModifierProducts}
          dataSource={modifierProducts.map((modifierProduct) => ({
            ...modifierProduct,
            count,
          }))}
          pagination={false}
          expandable={{
            showExpandColumn: false,
            rowExpandable: (record) =>
              record.modifierProducts.length > 0 ||
              record.extraIngredients.length > 0 ||
              record.removedIngredients.length > 0,
            expandedRowRender: (record) => (
              <>
                {getRemovedIngredientsTable(record.removedIngredients)}
                {getExtraIngredientsTable(record.extraIngredients, count)}
                {getModifierProductsTable(record.modifierProducts, count)}
              </>
            ),
          }}
        />
      )
    );
  };

  const getExtraIngredientsTable = (extraIngredients, count) => {
    return (
      extraIngredients.length > 0 && (
        <Table
          scroll={{ x: "100%" }}
          size="small"
          rowKey="id"
          bordered={!printable}
          showHeader={false}
          columns={columnsExtraIngredients}
          dataSource={extraIngredients.map((extraIngredient) => ({
            ...extraIngredient,
            count,
          }))}
          pagination={false}
        />
      )
    );
  };

  const getRemovedIngredientsTable = (removedIngredients) => {
    return (
      removedIngredients.length > 0 && (
        <Table
          scroll={{ x: "100%" }}
          size="small"
          rowKey="id"
          bordered={!printable}
          showHeader={false}
          columns={columnsRemovedIngredients}
          dataSource={[
            {
              id: "1",
              name: removedIngredients
                .map((removedIngredient) => removedIngredient.name)
                .join(", "),
            },
          ]}
          pagination={false}
        />
      )
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            ...(printable ? { cellPaddingBlockSM: 2 } : {}),
            ...(printable ? { cellPaddingInlineSM: 2 } : {}),
            ...(printable ? { colorText: "rgba(0, 0, 0, 1)" } : {}),
            ...(printable ? { borderColor: "#ffffff" } : {}),
            ...(printable ? { footerColor: "rgba(0, 0, 0, 1)" } : {}),
            ...(printable ? { footerBg: "#ffffff" } : {}),
          },
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Table
          scroll={{ x: "100%" }}
          style={{
            ...(printable ? {} : { flexGrow: 1 }),
            overflow: "auto",
            marginBottom: 8,
          }}
          size="small"
          rowKey={(record) => record.items[0].lineItemId}
          bordered={!printable}
          showHeader={false}
          columns={columns}
          dataSource={trendyolYemekPackage.lines}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
            showExpandColumn: false,
            rowExpandable: (record) =>
              record.modifierProducts.length > 0 ||
              record.extraIngredients.length > 0 ||
              record.removedIngredients.length > 0,
            expandedRowRender: (record) => (
              <>
                {getRemovedIngredientsTable(record.removedIngredients)}
                {getExtraIngredientsTable(
                  record.extraIngredients,
                  record.items.length
                )}
                {getModifierProductsTable(
                  record.modifierProducts,
                  record.items.length
                )}
              </>
            ),
          }}
          {...(printable
            ? {
                title: () => (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {billPrintSettings.showLogo &&
                        (billPrintSettings.logoImageFileName ||
                          billPrintSettings.logoImage) && (
                          <div style={{ paddingBottom: 8 }}>
                            <img
                              width={150}
                              src={`/images/tenants/${authorizationToken.tenant.code}/bill-print-settings/logo/${billPrintSettings.logoImageFileName}`}
                            />
                          </div>
                        )}
                      {billPrintSettings.showBusinessName &&
                        billPrintSettings.businessName && (
                          <div style={{ paddingBottom: 8 }}>
                            {billPrintSettings.businessName}
                          </div>
                        )}
                      {(billPrintSettings.showBusinessAddress ||
                        billPrintSettings.showBusinessCityBusinessDistrict) && (
                        <div
                          style={{
                            paddingBottom: 4,
                          }}
                        >
                          {billPrintSettings.showBusinessAddress &&
                            billPrintSettings.businessAddress && (
                              <>
                                {billPrintSettings.businessAddress}
                                <br />
                              </>
                            )}
                          {billPrintSettings.showBusinessCityBusinessDistrict && (
                            <>
                              {billPrintSettings.businessCity
                                ? billPrintSettings.businessCity
                                : ""}
                              {billPrintSettings.businessCity &&
                                billPrintSettings.businessDistrict &&
                                "/"}
                              {billPrintSettings.businessDistrict
                                ? billPrintSettings.businessDistrict
                                : ""}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <div>Trendyol Yemek</div>
                      <div>MH: {trendyolYemekPackage.callCenterPhone}</div>
                      <div>{trendyolYemekPackage.orderNumber}</div>
                      <div>
                        {dayjs(trendyolYemekPackage.packageCreationDate).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </div>
                      <div>
                        {`${trendyolYemekPackage.address.firstName} ${trendyolYemekPackage.address.lastName}`}
                      </div>

                      <div>
                        {`${t("Address")}: ${
                          trendyolYemekPackage.address.address1 &&
                          trendyolYemekPackage.address.address1
                        }
                        ${
                          trendyolYemekPackage.address.address2 &&
                          ` - ${trendyolYemekPackage.address.address2}`
                        }
                        ${` - ${t("Floor/DoorNumber")}:${
                          trendyolYemekPackage.address.floor
                        }/${trendyolYemekPackage.address.doorNumber}`}
                        ${
                          trendyolYemekPackage.address.addressDescription &&
                          ` - ${t("Description")}: ${
                            trendyolYemekPackage.address.addressDescription
                          }`
                        }`}
                      </div>
                      {billPrintSettings.showNote &&
                        trendyolYemekPackage.customerNote && (
                          <div>{`${t("Note")}: ${
                            trendyolYemekPackage.customerNote
                          }`}</div>
                        )}
                    </div>
                    <Divider
                      style={{
                        margin: 1,
                        borderWidth: "2px 0 0",
                        borderColor: "black",
                      }}
                      dashed
                    />
                  </div>
                ),
              }
            : {})}
        />

        {printable && (
          <Divider
            style={{
              margin: 1,
              borderWidth: "2px 0 0",
              borderColor: "black",
            }}
            dashed
          />
        )}

        <div>
          <Table
            scroll={{ x: "100%" }}
            bordered={!printable}
            size="small"
            showHeader={false}
            pagination={false}
            rowKey="key"
            dataSource={[
              {
                key: "total",
                col1: t("Total"),
                col2: trendyolYemekPackage.totalPrice,
              },
            ]}
            columns={[
              {
                key: "col1",
                dataIndex: "col1",
                width: "100%",
              },
              {
                key: "col2",
                dataIndex: "col2",
                align: "right",
              },
            ]}
            {...(printable
              ? {
                  footer: () => (
                    <>
                      {billPrintSettings.showFooterMessage &&
                        billPrintSettings.footerMessage && (
                          <>
                            <Divider
                              style={{
                                margin: 1,
                                borderWidth: "2px 0 0",
                                borderColor: "rgba(0,0,0,1)",
                              }}
                              dashed
                            />
                            <div
                              style={{
                                textAlign: "center",
                                paddingTop: 4,
                              }}
                            >
                              {billPrintSettings.footerMessage}
                            </div>
                          </>
                        )}
                    </>
                  ),
                }
              : {})}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default TrendyolYemekBill;
