import { UserAddOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row } from "antd";
import UserList from "../../components/user/UserList";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { getTenant } from "../../apis/tenantApis";

const UserListPage = () => {
  const { tenantId } = useParams();

  const [tenant, setTenant] = useState({});

  const [loadingGetTenant, setLoadingGetTenant] = useState(true);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const response = await getTenant(tenantId);
        setTenant(response.data);
        setLoadingGetTenant(false);
      } catch (error) {
        handleError(error);
        navigate("/tenants/list");
      }
    };

    fetchTenant();
  }, []);

  return (
    !loadingGetTenant && (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card size="small" title="Tenant Info">
            <Descriptions>
              <Descriptions.Item label="Tenant Name">
                {tenant.name}
              </Descriptions.Item>
              <Descriptions.Item label="Tenant Address">
                {tenant.address}
              </Descriptions.Item>
              <Descriptions.Item label="Tenant City/District">
                {tenant.city}/{tenant.district}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24}>
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            loading={loadingGetTenant}
            onClick={() => {
              navigate(`/tenants/${tenantId}/users/create`);
            }}
          >
            Create User
          </Button>
        </Col>
        <Col xs={24}>
          <UserList tenantId={tenantId} />
        </Col>
      </Row>
    )
  );
};

export default UserListPage;
