import { Button, Card, Col, DatePicker, Form, Row, Statistic } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListPayments } from "../../apis/paymentApis";
import { searchListOrderProducts } from "../../apis/orderProductApis";
import { searchListOrders } from "../../apis/orderApis";
import { formatTL, validateAndGetDateParam } from "../../common/CommonUtils";
import Bill from "../../components/order/Bill";
import { useTranslation } from "react-i18next";

const StatisticsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const [orders, setOrders] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchListOrders, setLoadingSearchListOrders] = useState(true);
  const [loadingSearchListOrderProducts, setLoadingSearchListOrderProducts] =
    useState(true);
  const [loadingSearchListPayments, setLoadingSearchListPayments] =
    useState(true);

  const handleError = useErrorHandler();

  const createdDateTimeMinParam = searchParams.get("createdDateTimeMin");
  const createdDateTimeMaxParam = searchParams.get("createdDateTimeMax");

  const createdDateTimeMin = validateAndGetDateParam(
    createdDateTimeMinParam,
    dayjs().hour(0).minute(0).second(0)
  );
  const createdDateTimeMax = validateAndGetDateParam(
    createdDateTimeMaxParam,
    dayjs().hour(23).minute(59).second(59)
  );

  const fakeOrderProductMap = new Map();
  orderProducts.forEach((orderProduct) => {
    const key = orderProduct.productName + orderProduct.productPrice;
    fakeOrderProductMap.set(
      key,
      fakeOrderProductMap.get(key)
        ? {
            ...fakeOrderProductMap.get(key),
            quantity:
              fakeOrderProductMap.get(key).quantity + orderProduct.quantity,
          }
        : {
            id: key,
            productName: orderProduct.productName,
            productPrice: orderProduct.productPrice,
            quantity: orderProduct.quantity,
          }
    );
  });

  useEffect(() => {
    const fetchOrders = async () => {
      const orderSearchDTO = {
        createdDateTimeMin: createdDateTimeMin.format("YYYY-MM-DD HH:mm:ss"),
        createdDateTimeMax: createdDateTimeMax.format("YYYY-MM-DD HH:mm:ss"),
        statusList: ["CLOSE"],
      };
      setLoadingSearchListOrders(true);
      setLoadingSearchListOrderProducts(true);
      setLoadingSearchListPayments(true);
      try {
        const response = await searchListOrders(orderSearchDTO);
        setOrders(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListOrders(false);
    };

    fetchOrders();
  }, [createdDateTimeMinParam, createdDateTimeMaxParam, retriggerValue]);

  useEffect(() => {
    if (!loadingSearchListOrders) {
      if (orders.length > 0) {
        const fetchOrderProducts = async () => {
          const orderProductSearchDTO = {
            orderIdList: orders.map((order) => order.id),
          };

          try {
            const response = await searchListOrderProducts(
              orderProductSearchDTO
            );
            setOrderProducts(response.data);
          } catch (error) {
            handleError(error);
          }
          setLoadingSearchListOrderProducts(false);
        };

        const fetchPayments = async () => {
          const paymentSearchDTO = {
            orderIdList: orders.map((order) => order.id),
          };

          try {
            const response = await searchListPayments(paymentSearchDTO);
            setPayments(response.data);
          } catch (error) {
            handleError(error);
          }
          setLoadingSearchListPayments(false);
        };

        fetchOrderProducts();
        fetchPayments();
      } else {
        setOrderProducts([]);
        setPayments([]);
        setLoadingSearchListOrderProducts(false);
        setLoadingSearchListPayments(false);
      }
    }
  }, [loadingSearchListOrders]);

  const onFinish = (values) => {
    if (values.createdDateTimeMin) {
      searchParams.set(
        "createdDateTimeMin",
        dayjs(values.createdDateTimeMin).second(0)
      );
    } else {
      searchParams.delete("createdDateTimeMin");
    }
    if (values.createdDateTimeMax) {
      searchParams.set(
        "createdDateTimeMax",
        dayjs(values.createdDateTimeMax).second(59)
      );
    } else {
      searchParams.delete("createdDateTimeMax");
    }

    setSearchParams(searchParams);
    setRetriggerValue((prevState) => prevState + 1);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card
          size="small"
          title={t("Value Filter Form", { value: t("Statistics") })}
        >
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="createdDateTimeMin"
                  label={t("Start Date")}
                  initialValue={createdDateTimeMin}
                  required
                  rules={[
                    {
                      required: true,
                      message: t("Please select value!", { value: t("Date") }),
                    },
                  ]}
                >
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    style={{ width: "100%" }}
                    showTime={{
                      format: "HH:mm",
                    }}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="createdDateTimeMax"
                  label={t("End Date")}
                  initialValue={createdDateTimeMax}
                  required
                  rules={[
                    {
                      required: true,
                      message: t("Please select value!", { value: t("Date") }),
                    },
                  ]}
                >
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    style={{ width: "100%" }}
                    showTime={{
                      format: "HH:mm",
                    }}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Row gutter={16}>
                    <Col style={{ flexGrow: 1 }}></Col>
                    <Col>
                      <Button
                        disabled={
                          loadingSearchListOrders ||
                          loadingSearchListOrderProducts ||
                          loadingSearchListPayments
                        }
                        onClick={() => {
                          form.setFieldValue(
                            "createdDateTimeMin",
                            dayjs().hour(0).minute(0).second(0)
                          );
                          form.setFieldValue(
                            "createdDateTimeMax",
                            dayjs().hour(23).minute(59).second(59)
                          );
                        }}
                      >
                        {t("Reset")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={
                          loadingSearchListOrders ||
                          loadingSearchListOrderProducts ||
                          loadingSearchListPayments
                        }
                      >
                        {t("Filter")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col xs={12} sm={6}>
        <Card>
          <Statistic
            loading={loadingSearchListOrders}
            title={t("Order Count")}
            value={orders.length}
          />
        </Card>
      </Col>
      <Col xs={12} sm={6}>
        <Card>
          <Statistic
            loading={loadingSearchListOrders}
            title={t("Total Customer Count")}
            value={orders.reduce((sum, order) => sum + order.customerCount, 0)}
          />
        </Card>
      </Col>
      <Col xs={12} sm={6}>
        <Card>
          <Statistic
            title={t("Total Turnover")}
            loading={loadingSearchListOrderProducts}
            value={formatTL(
              orderProducts.reduce(
                (sum, orderProduct) =>
                  sum + orderProduct.quantity * orderProduct.productPrice,
                0
              )
            )}
          />
        </Card>
      </Col>
      <Col xs={12} sm={6}>
        <Card>
          <Statistic
            title={t("Total Payments")}
            loading={loadingSearchListPayments}
            value={formatTL(
              payments.reduce((sum, payment) => sum + payment.amount, 0)
            )}
          />
        </Card>
      </Col>
      {
        <Col xs={24}>
          <Card
            bodyStyle={{ padding: 0 }}
            loading={
              loadingSearchListOrderProducts || loadingSearchListPayments
            }
          >
            <Bill
              orderProducts={Array.from(fakeOrderProductMap.values()).sort(
                (a, b) => b.quantity - a.quantity
              )}
              payments={payments}
              readOnly
            />
          </Card>
        </Col>
      }
    </Row>
  );
};

export default StatisticsPage;
