import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { ConfigProvider } from "antd";
import "./i18n/i18n";
import { PrimeReactProvider } from "primereact/api";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Form: {
            itemMarginBottom: 8,
          },
          Tabs: {
            horizontalMargin: "0 0 4px 0",
          },
        },
        token: {
          fontSize: 15,
          colorText: "#000000",
          colorBorder: "rgba(0, 0, 0, 0.30)",
          colorBorderSecondary: "rgba(0, 0, 0, 0.40)",
          borderRadiusXS: 0,
          borderRadiusSM: 0,
          borderRadius: 0,
          borderRadiusLG: 0,
          borderRadiusOuter: 0,
          fontFamily: "'Trebuchet MS', sans-serif",
        },
      }}
    >
      <PrimeReactProvider
        value={{
          pt: {
            card: {
              root: {
                className:
                  "text-black-alpha-90 border-1 border-black-alpha-40 shadow-none",
              },
            },
            datatable: {
              column: {
                bodyCell: {
                  className: "border-600",
                  style: { color: "#000000" },
                },
                footerCell: {
                  className: "border-600",
                  style: { color: "#000000" },
                },
                headerCell: {
                  className: "border-600",
                  style: { color: "#000000" },
                },
              },
            },
            panelmenu: {
              // root:{className:"border-1"},
              headerContent: { className: "border-gray-400" },
            },
            tabpanel: { headerAction: { className: "border-1" } },
          },
        }}
      >
        <App />
      </PrimeReactProvider>
    </ConfigProvider>
  </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
