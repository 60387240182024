import axios from "axios";

export const createUser = (body, tenantId) => {
  return axios.post(
    `/api/1.0${tenantId ? `/tenants/${tenantId}` : ""}/users`,
    body
  );
};

export const searchUsers = (page, size, userSearchDTO, tenantId) => {
  const { firstName, lastName } = userSearchDTO;

  const params = new URLSearchParams();
  params.append("page", page);
  params.append("size", size);
  if (firstName) {
    params.append("firstName", firstName);
  }
  if (lastName) {
    params.append("lastName", lastName);
  }

  return axios.get(
    `/api/1.0${
      tenantId ? `/tenants/${tenantId}` : ""
    }/users/page?${params.toString()}`
  );
};

export const updatePassword = (body) => {
  return axios.put("/api/1.0/users/password", body);
};
