import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTable, updateTable } from "../../apis/tableApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import { isNumber } from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";

const TableUpdatePage = () => {
  const { tableId } = useParams();

  const [table, setTable] = useState({});

  const [loadingGetTable, setLoadingGetTable] = useState(true);
  const [loadingUpdateTable, setLoadingUpdateTable] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchTable = async () => {
      try {
        const response = await getTable(tableId);
        setTable(response.data);
        setLoadingGetTable(false);
      } catch (error) {
        handleError(error);
        navigate("/tables/list");
      }
    };

    if (isNumber(tableId)) {
      fetchTable();
    } else {
      message.error("Not valid id");
      navigate("/tables/list");
    }
  }, []);

  const onFinish = async (values) => {
    const body = {
      name: values.tableName,
      capacity: values.capacity,
      position: values.position,
      status: values.status,
    };

    setLoadingUpdateTable(true);
    try {
      await updateTable(tableId, body);
      message.success(t("Value updated successfully.", { value: t("Table") }));
      navigate("/tables/list");
    } catch (error) {
      handleError(error);
      setLoadingUpdateTable(false);
    }
  };

  return (
    !loadingGetTable && (
      <Form
        layout="vertical"
        onFinish={onFinish}
        disabled={table.status === "DELETED"}
      >
        <Card
          size="small"
          title={t("Value Update Form", { value: t("Table") })}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="tableName"
                label={t("Value Name", { value: t("Table") })}
                initialValue={table.name}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Value Name", { value: t("Table") }),
                    }),
                  },
                  {
                    min: 1,
                    message: t("Minimum value characters!", { value: 1 }),
                  },
                  {
                    max: 10,
                    message: t("Maximum value characters!", { value: 10 }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="capacity"
                initialValue={table.capacity}
                label={t("Table Capacity")}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Table Capacity"),
                    }),
                  },
                  {
                    min: 1,
                    message: t("Minimum value!", { value: 1 }),
                    type: "number",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="position"
                label={t("Value Position", { value: t("Table") })}
                initialValue={table.position}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Value Position", { value: t("Table") }),
                    }),
                  },
                  {
                    type: "number",
                    min: 0,
                    message: t("Minimum value!", { value: 0 }),
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="status"
                label={t("Status")}
                initialValue={table.status}
                rules={[
                  {
                    required: true,
                    message: t("Please select value!", { value: "Status" }),
                  },
                ]}
              >
                <Select>
                  <Select.Option value="ACTIVE">{t("Active")}</Select.Option>
                  <Select.Option value="PASSIVE">{t("Passive")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingUpdateTable}
            >
              {t("Update")}
            </Button>
          </Form.Item>
        </Card>
      </Form>
    )
  );
};

export default TableUpdatePage;
