import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListCategories, updateCategory } from "../../apis/categoryApis";
import { isNumber } from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";

const CategoryUpdatePage = () => {
  const { categoryId } = useParams();

  const [categories, setCategories] = useState([]);

  const [loadingGetCategories, setLoadingGetCategories] = useState(true);
  const [loadingUpdateCategory, setLoadingUpdateCategory] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const category = categories.find(
    (category) => category.id === Number(categoryId)
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await searchListCategories();
        if (
          !response.data.find((category) => category.id === Number(categoryId))
        ) {
          message.error("Category not found.");
          navigate("/categories/list");
        }
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetCategories(false);
    };

    if (isNumber(categoryId)) {
      fetchCategories();
    } else {
      message.error("Not valid id");
      navigate("/categories/list");
    }
  }, []);

  const onFinish = async (values) => {
    const body = {
      parentCategoryId: values.parentCategoryId
        ? values.parentCategoryId
        : null,
      name: values.categoryName,
      position: values.position,
      showInQrMenu: values.showInQrMenu,
    };

    setLoadingUpdateCategory(true);
    try {
      await updateCategory(categoryId, body);
      message.success(t("Value updated.", { value: t("Category") }));
      navigate("/categories/list");
    } catch (error) {
      handleError(error);
      setLoadingUpdateCategory(false);
    }
  };

  return (
    !loadingGetCategories && (
      <Form layout="vertical" onFinish={onFinish}>
        <Card
          size="small"
          title={t("Value Update Form", { value: t("Category") })}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="parentCategoryId"
                    label={t("Value Name", { value: t("Parent Category") })}
                    initialValue={category.parentCategoryId}
                  >
                    <Select
                      allowClear
                      options={categories
                        .filter(
                          (category) =>
                            category.parentCategoryId === null &&
                            category.id !== Number(categoryId)
                        )
                        .map((category) => ({
                          value: category.id,
                          label: category.name,
                        }))}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="categoryName"
                label={t("Value Name", { value: t("Category") })}
                initialValue={category.name}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Value Name", { value: t("Category") }),
                    }),
                  },
                  {
                    min: 2,
                    message: t("Minimum value characters!", { value: 2 }),
                  },
                  {
                    max: 20,
                    message: t("Maximum value characters!", { value: 20 }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="position"
                label={t("Value Position", { value: t("Category") })}
                initialValue={category.position}
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", {
                      value: t("Value Position", { value: t("Category") }),
                    }),
                  },
                  {
                    type: "number",
                    min: 0,
                    message: t("Minimum value!", { value: 0 }),
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="showInQrMenu"
                valuePropName="checked"
                initialValue={category.showInQrMenu}
              >
                <Checkbox>{t("Show in QR menu")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingUpdateCategory}
            >
              {t("Update")}
            </Button>
          </Form.Item>
        </Card>
      </Form>
    )
  );
};

export default CategoryUpdatePage;
