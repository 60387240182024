import { useEffect, useState } from "react";
import {
  getQrMenuSettings,
  updateQrMenuSettings,
} from "../../apis/qrMenuSettingsApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  QRCode,
  Row,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import UploadImage from "../../components/common/UploadImage";
import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import { EyeOutlined } from "@ant-design/icons";
import QrMenuPage from "../QrMenuPage";

const QrMenuSettingsPage = () => {
  const [qrMenuSettings, setQrMenuSettings] = useState({});

  const [logoImage, setLogoImage] = useState(null);
  const [loadingLogoImage, setLoadingLogoImage] = useState(true);

  // const [backgroundImage, setBackgroundImage] = useState(null);
  // const [loadingBackgroundImage, setLoadingBackgroundImage] = useState(true);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [loadingGetQrMenuSettings, setLoadingGetQrMenuSettings] =
    useState(true);
  const [loadingUpdateQrMenuSettings, setLoadingUpdateQrMenuSettings] =
    useState(false);

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const handleError = useErrorHandler();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchQrMenuSettings = async () => {
      try {
        const response = await getQrMenuSettings();
        setQrMenuSettings(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetQrMenuSettings(false);
    };

    fetchQrMenuSettings();
  }, []);

  const onFinish = async (values) => {
    const body = {
      businessName: values.businessName ? values.businessName : null,
      logoImage: logoImage ? logoImage.split("base64,")[1] : null,
      // backgroundImage: backgroundImage
      //   ? backgroundImage.split("base64,")[1]
      //   : null,
      // showProductDescription: values.showProductDescription,
      // showProductImage: values.showProductImage,
    };
    setLoadingUpdateQrMenuSettings(true);
    try {
      await updateQrMenuSettings(body);
      message.success(t("Value updated.", { value: t("Qr Menu Settings") }));
    } catch (error) {
      handleError(error);
    }
    setLoadingUpdateQrMenuSettings(false);
  };

  return (
    !loadingGetQrMenuSettings && (
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Card
              size="small"
              title={t("Value Update Form", {
                value: t("Qr Menu Settings"),
              })}
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name="businessName"
                    label={t("Value Name", { value: t("Business") })}
                    initialValue={qrMenuSettings.businessName}
                    rules={[
                      {},
                      {
                        max: 100,
                        message: "Maximum 100 characters!",
                      },
                    ]}
                  >
                    <Input
                      value={qrMenuSettings.businessName}
                      onChange={(event) => {
                        setQrMenuSettings((prevState) => ({
                          ...prevState,
                          businessName: event.target.value,
                        }));
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item label={t("Logo Image")}>
                    <UploadImage
                      setLoading={setLoadingLogoImage}
                      setImage={setLogoImage}
                      {...(qrMenuSettings.logoImageFileName
                        ? {
                            fileName: "logo",
                            src: `/images/tenants/${authorizationToken.tenant.code}/qr-menu/logo/${qrMenuSettings.logoImageFileName}`,
                          }
                        : {})}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} lg={12}>
                  <Form.Item label={t("Background Image")}>
                    <UploadImage
                      setLoading={setLoadingBackgroundImage}
                      setImage={setBackgroundImage}
                      {...(qrMenuSettings.backgroundImageFileName
                        ? {
                            fileName: "background",
                            src: `/images/tenants/${authorizationToken.tenant.id}/qr-menu/background/${qrMenuSettings.backgroundImageFileName}`,
                          }
                        : {})}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24}>
                  <Form.Item
                    name="showProductDescription"
                    valuePropName="checked"
                    initialValue={qrMenuSettings.showProductDescription}
                  >
                    <Checkbox
                      checked={qrMenuSettings.showProductDescription}
                      onChange={(event) => {
                        setQrMenuSettings((prevState) => ({
                          ...prevState,
                          showProductDescription: event.target.checked,
                        }));
                      }}
                    >
                      {t("Show Product Descripton")}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="showProductImage"
                    valuePropName="checked"
                    initialValue={qrMenuSettings.showProductImage}
                  >
                    <Checkbox
                      checked={qrMenuSettings.showProductImage}
                      onChange={(event) => {
                        setQrMenuSettings((prevState) => ({
                          ...prevState,
                          showProductImage: event.target.checked,
                        }));
                      }}
                    >
                      {t("Show Product Image")}
                    </Checkbox>
                  </Form.Item>
                </Col> */}
              </Row>

              <Form.Item style={{ marginBottom: 8 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={
                    loadingUpdateQrMenuSettings || loadingLogoImage
                    // || loadingBackgroundImage
                  }
                >
                  {t("Update")}
                </Button>

                <Button
                  style={{ marginLeft: 16 }}
                  // type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setPreviewOpen(true);
                  }}
                >
                  {t("See Menu")}
                </Button>
                <Modal
                  open={previewOpen}
                  footer={null}
                  onCancel={() => {
                    setPreviewOpen(false);
                  }}
                  destroyOnClose
                >
                  <QrMenuPage
                    previewMode
                    tenantCode={authorizationToken.tenant.code}
                    qrMenuSettings={qrMenuSettings}
                  />
                </Modal>
              </Form.Item>
            </Card>
          </Form>
        </Col>
        <Col xs={24} lg={12}>
          <QRCode
            value={`${process.env.REACT_APP_DOMAIN}/${authorizationToken.tenant.code}/qr-menu`}
          />
        </Col>
      </Row>
    )
  );
};

export default QrMenuSettingsPage;
