import { useEffect, useState } from "react";
import { getQrMenuDetails } from "../apis/qrMenuBffApis";
import useErrorHandler from "../hooks/useErrorHandler";
import {
  Card,
  Col,
  ConfigProvider,
  Divider,
  Grid,
  Image,
  List,
  Row,
  Tabs,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { formatTL } from "../common/CommonUtils";

const QrMenuPage = (props) => {
  const { previewMode } = props;

  const params = useParams();

  const tenantCode = previewMode ? props.tenantCode : params.tenantCode;

  const screens = Grid.useBreakpoint();

  const [qrMenuDetails, setQrMenuDetails] = useState({});
  const [loadingGetQrMenuDetails, setLoadingGetQrMenuDetails] = useState(true);

  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchQrMenuDetails = async () => {
      try {
        const response = await getQrMenuDetails(tenantCode);
        setQrMenuDetails(response.data);
      } catch (error) {
        handleError(error);
      }

      setLoadingGetQrMenuDetails(false);
    };

    fetchQrMenuDetails();
  }, []);

  return (
    !loadingGetQrMenuDetails && (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            // style={{ background: `url(${backgroundImage})` }}
            size="small"
            title={
              qrMenuDetails.qrMenuSettings.logoImageFileName ? (
                <Image
                  style={{ maxWidth: 300 }}
                  preview={false}
                  src={`/images/tenants/${tenantCode}/qr-menu/logo/${qrMenuDetails.qrMenuSettings.logoImageFileName}`}
                />
              ) : (
                <Typography.Title level={3} style={{ textAlign: "center" }}>
                  {qrMenuDetails.qrMenuSettings.businessName}
                </Typography.Title>
              )
            }
            headStyle={{ textAlign: "center" }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    cardBg: "#ffd666",
                    colorBgContainer: "#d4b106",
                    itemSelectedColor: "#ffffff",
                  },
                },
              }}
            >
              <Tabs
                type="card"
                items={qrMenuDetails.categoryList.map((category) => ({
                  key: category.id.toString(),
                  label: category.name,
                  children: (
                    <Tabs
                      type="card"
                      items={category.childCategoryList.map(
                        (childCategory) => ({
                          key: childCategory.id.toString(),
                          label: childCategory.name,
                          children: (
                            <List
                              dataSource={childCategory.productList}
                              renderItem={(product) => (
                                <List.Item
                                  key={product.id}
                                  extra={
                                    product.imageFileName ? (
                                      <Image
                                        width={
                                          screens.xs
                                            ? 100
                                            : screens.sm
                                            ? 150
                                            : screens.md
                                            ? 200
                                            : 250
                                        }
                                        alt="productImage"
                                        src={`/images/tenants/${tenantCode}/products/${product.imageFileName}`}
                                      />
                                    ) : null
                                  }
                                >
                                  <List.Item.Meta
                                    title={product.name}
                                    description={product.description}
                                  />
                                  <div style={{ margin: 8 }}>
                                    <Typography.Text strong>
                                      {formatTL(product.price)}
                                    </Typography.Text>
                                    <Divider type="vertical" />
                                  </div>
                                </List.Item>
                              )}
                            />
                          ),
                        })
                      )}
                    />
                  ),
                }))}
              />
            </ConfigProvider>
          </Card>
        </Col>
      </Row>
    )
  );
};

export default QrMenuPage;
