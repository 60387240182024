import ProductCard from "./ProductCard";
import { TabPanel, TabView } from "primereact/tabview";

const MenuTabContent = (props) => {
  const { categories, products, order, setOrderProducts } = props;

  const parentCategoryIdAndSubCategoryListMap = new Map();
  const subCategoryIdAndProductListMap = new Map();

  const parentCategories = categories.filter(
    (category) => category.parentCategoryId === null
  );

  parentCategories.forEach((parentCategory) => {
    parentCategoryIdAndSubCategoryListMap.set(parentCategory.id, []);
  });

  categories
    .filter((category) => category.parentCategoryId !== null)
    .forEach((subCategory) => {
      parentCategoryIdAndSubCategoryListMap.set(subCategory.parentCategoryId, [
        ...parentCategoryIdAndSubCategoryListMap.get(
          subCategory.parentCategoryId
        ),
        subCategory,
      ]);

      subCategoryIdAndProductListMap.set(subCategory.id, []);
    });

  products.forEach((product) => {
    subCategoryIdAndProductListMap.set(product.categoryId, [
      ...subCategoryIdAndProductListMap.get(product.categoryId),
      product,
    ]);
  });

  return (
    <TabView
      pt={{
        panelContainer: { className: "py-1 px-0" },
        navContent: { className: "inline" },
      }}
    >
      {parentCategories.map((parentCategory) => {
        return (
          <TabPanel
            key={parentCategory.id}
            header={parentCategory.name}
            pt={{ headerAction: { className: "p-2" } }}
          >
            <TabView
              pt={{
                panelContainer: { className: "py-1 px-0" },
                navContent: { className: "inline" },
              }}
            >
              {parentCategoryIdAndSubCategoryListMap
                .get(parentCategory.id)
                .map((subCategory) => {
                  return (
                    <TabPanel
                      key={subCategory.id}
                      header={subCategory.name}
                      pt={{
                        headerAction: { className: "p-2" },
                      }}
                    >
                      <div className="grid w-full">
                        {subCategoryIdAndProductListMap
                          .get(subCategory.id)
                          .map((product) => {
                            return (
                              <div
                                key={product.id}
                                className="col-6 sm:col-4 md:col-3 lg:col-2"
                              >
                                <ProductCard
                                  order={order}
                                  product={product}
                                  setOrderProducts={setOrderProducts}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </TabPanel>
                  );
                })}
            </TabView>
          </TabPanel>
        );
      })}
    </TabView>
  );
};

export default MenuTabContent;
