import axios from "axios";

export const createBalanceAccountTransaction = (balanceAccountId, body) => {
  return axios.post(
    `/api/1.0/balance-accounts/${balanceAccountId}/balance-account-transactions`,
    body
  );
};

export const getBalanceAccountTransactionListByBalanceAccountId = (
  balanceAccountId
) => {
  return axios.get(
    `/api/1.0/balance-accounts/${balanceAccountId}/balance-account-transactions/list`
  );
};
