import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListTables } from "../../apis/tableApis";
import { searchListCategories } from "../../apis/categoryApis";
import { searchListProducts } from "../../apis/productApis";
import { searchListOrders } from "../../apis/orderApis";
import { searchListOrderProducts } from "../../apis/orderProductApis";
import OrderCard from "../../components/order/OrderCard";
import { searchListPayments } from "../../apis/paymentApis";
import { getBillPrintSettings } from "../../apis/billPrintSettingsApis";
import { getTrendyolYemekPackagePage } from "../../apis/trendyolYemekOrderApis";
import TrendyolYemekTabContent from "../../components/order-management/TrendyolYemekTabContent";
import { t } from "i18next";
import newOrderAlertSound from "../../assets/sounds/new-order-alert.wav";
import { getTrendyolYemekIntegrationSettings } from "../../apis/trendyolYemekIntegrationSettingsApi";
import { TabView, TabPanel } from "primereact/tabview";
import { Badge } from "primereact/badge";

const OrderManagementPage = () => {
  const [tables, setTables] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [billPrintSettings, setBillPrintSettings] = useState({});
  const [trendyolYemekPackagePage, setTrendyolYemekPackagePage] = useState({
    page: 0,
    size: 30,
    content: [],
  });

  const [isTrendyolYemekActive, setIsTrendyolYemekActive] = useState(false);
  const [trendyolYemekPageParam, setTrendyolYemekPageParam] = useState(0);
  const [trendyolYemekPageRefreshCount, setTrendyolYemekPageRefreshCount] =
    useState(0);

  const [loadingSearchListTables, setLoadingSearchListTables] = useState(true);
  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingSearchListProducts, setLoadingSearchListProducts] =
    useState(true);
  const [loadingSearchListOrders, setLoadingSearchListOrders] = useState(true);
  const [loadingSearchListOrderProducts, setLoadingSearchListOrderProducts] =
    useState(true);
  const [loadingSearchListPayments, setLoadingSearchListPayments] =
    useState(true);
  const [loadingGetBillPrintSettings, setLoadingGetBillPrintSettings] =
    useState(true);
  const [
    loadingGetTrendyolYemekPackagePage,
    setLoadingGetTrendyolYemekPackagePage,
  ] = useState(true);

  const handleError = useErrorHandler();

  const tableIdAndOrderMap = new Map();
  const orderIdAndOrderProductMap = new Map();
  const orderIdAndPaymentMap = new Map();

  tableIdAndOrderMap.set("TAKEAWAY", {});
  tableIdAndOrderMap.set("DELIVERY", {});
  tables.forEach((table) => {
    tableIdAndOrderMap.set(table.id, {});
  });

  orders.forEach((order) => {
    tableIdAndOrderMap.set(
      order.tableId !== null ? order.tableId : order.type,
      order
    );
    orderIdAndOrderProductMap.set(order.id, []);
    orderIdAndPaymentMap.set(order.id, []);
  });

  orders.length > 0 &&
    orderProducts.forEach((orderProduct) => {
      orderIdAndOrderProductMap.set(orderProduct.orderId, [
        ...orderIdAndOrderProductMap.get(orderProduct.orderId),
        orderProduct,
      ]);
    });

  orders.length > 0 &&
    payments.forEach((payment) => {
      orderIdAndPaymentMap.set(payment.orderId, [
        ...orderIdAndPaymentMap.get(payment.orderId),
        payment,
      ]);
    });

  const emptyTables = tables.filter(
    (table) => !tableIdAndOrderMap.get(table.id).id
  );

  const readyToRender =
    !loadingSearchListTables &&
    !loadingSearchListCategories &&
    !loadingSearchListProducts &&
    !loadingSearchListOrders &&
    !loadingSearchListOrderProducts &&
    !loadingSearchListPayments &&
    !loadingGetBillPrintSettings;

  useEffect(() => {
    const fetchTables = async () => {
      const tableSearchDTO = {
        statusList: ["ACTIVE"],
      };
      try {
        const response = await searchListTables(tableSearchDTO);
        setTables(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListTables(false);
    };

    const fetchCategories = async () => {
      const categorySearchDTO = {
        statusList: ["ACTIVE"],
      };
      try {
        const response = await searchListCategories(categorySearchDTO);
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    const fetchProducts = async () => {
      const productSearchDTO = {
        statusList: ["ACTIVE"],
      };
      try {
        const response = await searchListProducts(productSearchDTO);
        setProducts(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListProducts(false);
    };

    const fetchOpenOrders = async () => {
      const orderSearchDTO = {
        statusList: ["OPEN"],
      };
      try {
        const response = await searchListOrders(orderSearchDTO);
        setOrders(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListOrders(false);
    };

    const fetchOrderProductsByOrderStatusOpen = async () => {
      const orderProductSearchDTO = {
        orderStatus: "OPEN",
      };
      try {
        const response = await searchListOrderProducts(orderProductSearchDTO);
        setOrderProducts(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListOrderProducts(false);
    };

    const fetchPaymentsByOrderStatusOpen = async () => {
      const paymentSearchDTO = {
        orderStatus: "OPEN",
      };
      try {
        const response = await searchListPayments(paymentSearchDTO);
        setPayments(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListPayments(false);
    };

    const fetchBillPrintSettings = async () => {
      try {
        const response = await getBillPrintSettings();
        setBillPrintSettings(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetBillPrintSettings(false);
    };

    const fetchTrendyolYemekIntegrationSettings = async () => {
      try {
        await getTrendyolYemekIntegrationSettings();
        setIsTrendyolYemekActive(true);
      } catch (error) {
        if (error.response.status === 404) {
          setLoadingGetTrendyolYemekPackagePage(false);
        } else {
          handleError(error);
        }
      }
    };

    fetchTables();
    fetchCategories();
    fetchProducts();
    fetchOpenOrders();
    fetchOrderProductsByOrderStatusOpen();
    fetchPaymentsByOrderStatusOpen();
    fetchBillPrintSettings();
    fetchTrendyolYemekIntegrationSettings();
  }, []);

  useEffect(() => {
    const fetchTrendyolYemekPackagePage = async () => {
      setLoadingGetTrendyolYemekPackagePage(true);
      try {
        const response = await getTrendyolYemekPackagePage(
          trendyolYemekPageParam,
          30
        );
        if (
          response.data.content.filter(
            (order) => order.packageStatus === "Created"
          ).length > 0
        ) {
          const sound = new Audio(newOrderAlertSound);
          sound.play();
        }
        setTrendyolYemekPackagePage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetTrendyolYemekPackagePage(false);
    };

    if (!isTrendyolYemekActive) {
      return;
    }

    fetchTrendyolYemekPackagePage();

    const interval = setInterval(() => {
      fetchTrendyolYemekPackagePage();
    }, 15000);

    return () => clearInterval(interval);
  }, [
    isTrendyolYemekActive,
    trendyolYemekPageParam,
    trendyolYemekPageRefreshCount,
  ]);

  return (
    <TabView>
      <TabPanel header={t("Restaurant")}>
        {readyToRender && (
          <div className="grid">
            <div className="col-6 md:col-3 lg:col-2">
              <OrderCard
                type="TAKEAWAY"
                order={tableIdAndOrderMap.get("TAKEAWAY")}
                categories={categories}
                products={products}
                orderProducts={orderIdAndOrderProductMap.get(
                  tableIdAndOrderMap.get("TAKEAWAY").id
                )}
                payments={orderIdAndPaymentMap.get(
                  tableIdAndOrderMap.get("TAKEAWAY").id
                )}
                setOrders={setOrders}
                setOrderProducts={setOrderProducts}
                setPayments={setPayments}
                billPrintSettings={billPrintSettings}
              />
            </div>
            <div className="col-6 md:col-3 lg:col-2">
              <OrderCard
                type="DELIVERY"
                order={tableIdAndOrderMap.get("DELIVERY")}
                categories={categories}
                products={products}
                orderProducts={orderIdAndOrderProductMap.get(
                  tableIdAndOrderMap.get("DELIVERY").id
                )}
                payments={orderIdAndPaymentMap.get(
                  tableIdAndOrderMap.get("DELIVERY").id
                )}
                setOrders={setOrders}
                setOrderProducts={setOrderProducts}
                setPayments={setPayments}
                billPrintSettings={billPrintSettings}
              />
            </div>
            {tables.map((table) => {
              return (
                <div key={table.id} className="col-6 md:col-3 lg:col-2">
                  <OrderCard
                    type="TABLE"
                    table={table}
                    emptyTables={emptyTables}
                    order={tableIdAndOrderMap.get(table.id)}
                    categories={categories}
                    products={products}
                    orderProducts={orderIdAndOrderProductMap.get(
                      tableIdAndOrderMap.get(table.id).id
                    )}
                    payments={orderIdAndPaymentMap.get(
                      tableIdAndOrderMap.get(table.id).id
                    )}
                    setOrders={setOrders}
                    setOrderProducts={setOrderProducts}
                    setPayments={setPayments}
                    billPrintSettings={billPrintSettings}
                  />
                </div>
              );
            })}
          </div>
        )}
      </TabPanel>
      <TabPanel
        header={
          <span className="p-overlay-badge">
            Trendyol Yemek&nbsp;&nbsp;
            <Badge
              severity="warning"
              value={
                loadingGetTrendyolYemekPackagePage ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "0.75rem" }}
                  />
                ) : (
                  trendyolYemekPackagePage.content.filter(
                    (order) =>
                      order.packageStatus === "Created" ||
                      order.packageStatus === "Picking" ||
                      order.packageStatus === "Invoiced" ||
                      order.packageStatus === "Shipped"
                  ).length
                )
              }
            />
          </span>
        }
      >
        <TrendyolYemekTabContent
          billPrintSettings={billPrintSettings}
          loadingGetTrendyolYemekPackagePage={
            loadingGetTrendyolYemekPackagePage
          }
          setLoadingGetTrendyolYemekPackagePage={
            setLoadingGetTrendyolYemekPackagePage
          }
          trendyolYemekPackagePage={trendyolYemekPackagePage}
          setTrendyolYemekPageParam={setTrendyolYemekPageParam}
          setTrendyolYemekPageRefreshCount={setTrendyolYemekPageRefreshCount}
        />
      </TabPanel>
      <TabPanel
        disabled
        header={
          <span className="p-overlay-badge">
            Getir Yemek&nbsp;&nbsp;
            <Badge severity="warning" value={0} />
          </span>
        }
      ></TabPanel>
      <TabPanel
        disabled
        header={
          <span className="p-overlay-badge">
            Yemek Sepeti&nbsp;&nbsp;
            <Badge severity="warning" value={0} />
          </span>
        }
      ></TabPanel>
    </TabView>
  );
};

export default OrderManagementPage;
