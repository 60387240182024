import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import textTR from "./textTR.json";
import textEN from "./textEN.json";
// import detector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: textEN,
  },
  tr: {
    translation: textTR,
  },
};

//TODO: Auto detect language

i18n
  .use(initReactI18next)
  // .use(detector)
  .init({
    resources,
    lng: localStorage.getItem("language") === "en" ? "en" : "tr",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
