import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { deleteTable, searchPageTables } from "../../apis/tableApis";
import {
  validateAndGetMultipleSelectParam,
  validateAndGetTextParam,
} from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";

const TableListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tablePage, setTablePage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchPageTables, setLoadingSearchPageTables] = useState(true);

  const handleError = useErrorHandler();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const pageParam = searchParams.get("page");
  const sizeParam = searchParams.get("size");
  const nameParam = searchParams.get("name");
  const statusListParam = searchParams.get("statusList");
  const name = validateAndGetTextParam(nameParam, 10);
  const statusList = validateAndGetMultipleSelectParam(
    statusListParam,
    ["ACTIVE", "PASSIVE"],
    ["ACTIVE", "PASSIVE"]
  );

  useEffect(() => {
    const fetchTables = async () => {
      const tableSearchDTO = {
        name,
        statusList,
      };
      setLoadingSearchPageTables(true);
      try {
        const response = await searchPageTables(
          pageParam - 1,
          sizeParam,
          tableSearchDTO
        );
        setTablePage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchPageTables(false);
    };

    fetchTables();
  }, [pageParam, sizeParam, nameParam, statusListParam, retriggerValue]);

  const onFinish = (values) => {
    if (values.tableName) {
      searchParams.set("name", values.tableName);
    } else {
      searchParams.delete("name");
    }

    searchParams.set("statusList", values.statusList);

    searchParams.set("page", 1);

    setSearchParams(searchParams);
    setRetriggerValue((prevState) => prevState + 1);
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Capacity"),
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) =>
        record.status === "ACTIVE" ? t("Active") : t("Passive"),
    },
    {
      title: t("Position"),
      dataIndex: "position",
      key: "position",
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <Space>
          <Space>
            <RouterLink to={`/tables/${record.id}/update`}>
              {t("Update")}
            </RouterLink>
          </Space>
          <Space>
            <Popconfirm
              title={t("Delete Value", { value: t("Table") })}
              description={t("Are you sure to delete the value?", {
                value: t("Table"),
              })}
              onConfirm={async () => {
                try {
                  await deleteTable(record.id);
                  message.success(t("Value deleted.", { value: record.name }));
                  setRetriggerValue((prevState) => prevState + 1);
                } catch (error) {
                  handleError(error);
                }
              }}
              okText={t("Yes")}
              cancelText={t("No")}
            >
              <Typography.Link>{t("Delete")}</Typography.Link>
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card
          size="small"
          title={t("Value Search Filter Form", { value: t("Table") })}
        >
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="tableName"
                  label={t("Name")}
                  initialValue={name}
                  rules={[
                    {
                      max: 10,
                      message: t("Maximum value characters!", { value: 10 }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="statusList"
                  label={t("Status")}
                  initialValue={statusList}
                  rules={[
                    {
                      required: true,
                      message: t("Please select value!", {
                        value: t("Status"),
                      }),
                    },
                  ]}
                >
                  <Select mode="multiple">
                    <Select.Option value="ACTIVE">{t("Active")}</Select.Option>
                    <Select.Option value="PASSIVE">
                      {t("Passive")}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Row gutter={16}>
                    <Col style={{ flexGrow: 1 }}></Col>
                    <Col>
                      <Button
                        loading={loadingSearchPageTables}
                        onClick={() => {
                          form.setFieldValue("tableName", undefined);
                          form.setFieldValue("statusList", [
                            "ACTIVE",
                            "PASSIVE",
                          ]);
                        }}
                      >
                        {t("Reset")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingSearchPageTables}
                      >
                        {t("Filter")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col xs={24}>
        <Table
          scroll={{ x: "100%" }}
          bordered
          columns={columns}
          dataSource={tablePage.content}
          pagination={{
            showSizeChanger: true,
            current: tablePage.number + 1,
            pageSize: tablePage.size,
            total: tablePage.totalElements,
          }}
          rowKey="id"
          onChange={(pagination, filters, sorter) => {
            searchParams.set("page", pagination.current);
            searchParams.set("size", pagination.pageSize);
            setSearchParams(searchParams);
          }}
          loading={loadingSearchPageTables}
        />
      </Col>
    </Row>
  );
};

export default TableListPage;
