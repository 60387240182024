const DashedDivider = () => {
  return (
    <hr
      className="border-dashed border-top-2 border-bottom-none"
      style={{ borderColor: "#000000" }}
    />
  );
};

export default DashedDivider;
