import { Button, Card, Col, Form, Input, Row, message } from "antd";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { createTenant } from "../../apis/tenantApis";

const TenantCreatePage = () => {
  const [loadingCreateTenant, setLoadingCreateTenant] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const onFinish = async (values) => {
    const body = {
      name: values.tenantName,
      code: values.tenantCode,
      address: values.address,
      city: values.city,
      district: values.district,
    };

    setLoadingCreateTenant(true);
    try {
      await createTenant(body);
      message.success("Tenant created successfully.");
      navigate("/tenants/list");
    } catch (error) {
      handleError(error);
      setLoadingCreateTenant(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Card size="small" title="Tenant Create Form">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="tenantName"
              label="Tenant Name"
              rules={[
                {
                  required: true,
                  message: "Please input tenant name!",
                },
                {
                  max: 100,
                  message: "Maximum 100 characters!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="tenantCode"
              label="Tenant Code"
              rules={[
                {
                  required: true,
                  message: "Please input tenant code!",
                },
                {
                  max: 100,
                  message: "Maximum 100 characters!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="address"
              label="Tenant Address"
              rules={[
                {
                  required: true,
                  message: "Please input tenant address!",
                },
              ]}
            >
              <Input.TextArea maxLength={250} />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="city"
              label="Tenant City"
              rules={[
                {
                  required: true,
                  message: "Please input tenant city!",
                },
                {
                  max: 30,
                  message: "Maximum 30 characters!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item
              name="district"
              label="Tenant District"
              rules={[
                {
                  required: true,
                  message: "Please input tenant district!",
                },
                {
                  max: 30,
                  message: "Maximum 30 characters!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginBottom: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreateTenant}
          >
            Create Tenant
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default TenantCreatePage;
