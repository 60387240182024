import dayjs from "dayjs";

export const formatTL = (value) => {
  return "₺" + value;
};

export const replaceTrToEn = (text) => {
  return text
    .replaceAll("Ğ", "g")
    .replaceAll("Ü", "u")
    .replaceAll("Ş", "s")
    .replaceAll("I", "i")
    .replaceAll("İ", "i")
    .replaceAll("Ö", "o")
    .replaceAll("Ç", "c")
    .replaceAll("ğ", "g")
    .replaceAll("ü", "u")
    .replaceAll("ş", "s")
    .replaceAll("ı", "i")
    .replaceAll("ö", "o")
    .replaceAll("ç", "c");
};

export const validateAndGetMultipleSelectParam = (
  param,
  validValueList,
  defaultValueList
) => {
  if (param) {
    const responseList = [...new Set(param.split(","))].filter((paramValue) =>
      validValueList.includes(paramValue)
    );

    if (responseList.length !== 0) {
      return responseList;
    }
  }
  return defaultValueList;
};

export const validateAndGetDateParam = (param, defaultValue) => {
  if (param) {
    const response = dayjs(param);
    if (response.isValid()) {
      return response;
    }
  }
  if (defaultValue) {
    return defaultValue;
  }
  return null;
};

export const validateAndGetIdParam = (param, keyValueMap) => {
  if (param) {
    const id = Number(param);
    if (!Number.isNaN(id) && keyValueMap.get(id) !== undefined) {
      return id;
    }
  }
  return null;
};

export const validateAndGetTextParam = (param, maxLength) => {
  if (param) {
    if (param.length <= maxLength) {
      return param;
    }
  }
  return null;
};

export const isNumber = (value) => {
  return !Number.isNaN(Number(value));
};
