import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Result, Row, Typography } from "antd";
import { useState } from "react";
import { createResetPasswordToken } from "../apis/resetPasswordTokenApis";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPasswordPage = () => {
  const [loadingCreateResetPasswordToken, setLoadingCreateResetPasswordToken] =
    useState(false);

  const [result, setResult] = useState(null);

  const { t } = useTranslation();

  const onFinish = async (values) => {
    const body = {
      username: values.username,
    };
    setLoadingCreateResetPasswordToken(true);
    try {
      await createResetPasswordToken(body);
      setResult("success");
    } catch (error) {
      setResult("fail");
    }
    setLoadingCreateResetPasswordToken(false);
  };

  return (
    <Row justify="center" align="middle">
      <Col
        style={{
          marginTop: "128px",
          width: "100%",
          maxWidth: "440px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        {result === null ? (
          <Form
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Typography.Title level={3}>{t("Reset Password")}</Typography.Title>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t("Please input value!", { value: t("Username") }),
                },
                {
                  min: 4,
                  message: t("Minimum value characters!", { value: 4 }),
                },
                {
                  max: 20,
                  message: t("Maximum value characters!", { value: 20 }),
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder={t("Username")} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadingCreateResetPasswordToken}
              >
                {t("Reset Password")}
              </Button>
            </Form.Item>
            <Link to="/login">{t("Login")}</Link>
          </Form>
        ) : result === "success" ? (
          <Result
            status="success"
            title={t("Email sent.")}
            subTitle={t("Please continue from the link we sent to your email.")}
            extra={
              <Link to="/login">
                <Button>{t("Go Login")}</Button>
              </Link>
            }
          />
        ) : (
          <Result
            status="error"
            title="Oops!"
            subTitle="Something went wrong. Please contact with developer."
            extra={
              <Link to="/login">
                <Button>{t("Go Login")}</Button>
              </Link>
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default ResetPasswordPage;
