import { Col, Popconfirm, Row, Space, Table, Typography, message } from "antd";
import { deleteCategory, searchListCategories } from "../../apis/categoryApis";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useTranslation } from "react-i18next";

const CategoryListPage = () => {
  const [categories, setCategories] = useState([]);
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);

  const handleError = useErrorHandler();

  const { t } = useTranslation();

  const parentCategories = categories.filter(
    (category) => category.parentCategoryId === null
  );

  const parentCategoryIdAndSubCategoriesMap = new Map();
  parentCategories.forEach((parentCategory) => {
    parentCategoryIdAndSubCategoriesMap.set(parentCategory.id, []);
  });

  categories
    .filter((category) => category.parentCategoryId !== null)
    .forEach((subCategory) => {
      parentCategoryIdAndSubCategoriesMap.set(subCategory.parentCategoryId, [
        ...parentCategoryIdAndSubCategoriesMap.get(
          subCategory.parentCategoryId
        ),
        subCategory,
      ]);
    });

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingSearchListCategories(true);
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, [retriggerValue]);

  const dataSource = parentCategories.map((parentCategory) => {
    const subCategories = parentCategoryIdAndSubCategoriesMap.get(
      parentCategory.id
    );

    return {
      ...parentCategory,
      children: subCategories.length > 0 ? subCategories : undefined,
    };
  });

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Position"),
      key: "position",
      dataIndex: "position",
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <Space>
          <Space>
            <RouterLink to={`/categories/${record.id}/update`}>
              {t("Update")}
            </RouterLink>
          </Space>
          <Space>
            <Popconfirm
              title={t("Delete Value", { value: t("Category") })}
              description={t("Are you sure to delete the value?", {
                value: t("Category"),
              })}
              onConfirm={async () => {
                try {
                  await deleteCategory(record.id);
                  message.success(t("Value deleted.", { value: record.name }));
                  setRetriggerValue((prevState) => prevState + 1);
                } catch (error) {
                  handleError(error);
                }
              }}
              okText={t("Yes")}
              cancelText={t("No")}
            >
              <Typography.Link>{t("Delete")}</Typography.Link>
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Table
          scroll={{ x: "100%" }}
          bordered
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey="id"
          loading={loadingSearchListCategories}
        />
      </Col>
    </Row>
  );
};

export default CategoryListPage;
