import axios from "axios";

export const createPayment = (orderId, body) => {
  return axios.post(`/api/1.0/orders/${orderId}/payments`, body);
};

export const searchListPayments = (paymentSearchDTO) => {
  const { orderIdList, orderStatus } = paymentSearchDTO;

  const params = new URLSearchParams();

  if (orderIdList) {
    params.append("orderIdList", orderIdList);
  }
  if (orderStatus) {
    params.append("orderStatus", orderStatus);
  }

  return axios.get(`/api/1.0/payments/list?${params.toString()}`);
};

export const deletePayment = (paymentId) => {
  return axios.delete(`/api/1.0/payments/${paymentId}`);
};
