import { Card, Col, Form, Input, Row, Select, message } from "antd";
import { updateOrder } from "../../apis/orderApis";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Calendar } from "primereact/calendar";
import FormWrapper from "../common/FormWrapper";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import {
  validateInputNumber,
  validateInputText,
} from "../../common/FormValidationUtils";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { t } from "i18next";

const OrderUpdateForm = (props) => {
  const {
    readOnly,
    emptyTables,
    order,
    setOrders,
    setOrderProducts,
    setPayments,
  } = props;

  const [loadingUpdateOrder, setLoadingUpdateOrder] = useState(false);

  const handleError = useErrorHandler();

  const formik = useFormik({
    initialValues: {
      tableId: order.tableId,
      customerCount: order.customerCount,
      note: order.note ? order.note : "",
      deliveryAddress: order.deliveryAddress ? order.deliveryAddress : "",
    },

    validate: (data) => {
      let errors = {};

      validateInputNumber(
        "customerCount",
        data.customerCount,
        true,
        1,
        null,
        errors
      );

      validateInputText("note", data.note, false, null, 250, errors);

      if (order.type === "DELIVERY") {
        validateInputText(
          "deliveryAddress",
          data.deliveryAddress,
          true,
          1,
          250,
          errors
        );
      }

      return errors;
    },

    onSubmit: async (values) => {
      const body = {
        tableId: values.tableId,
        customerCount: values.customerCount,
        note: values.note ? values.note : null,
        deliveryAddress: values.deliveryAddress ? values.deliveryAddress : null,
      };

      setLoadingUpdateOrder(true);
      try {
        const response = await updateOrder(order.id, body);

        setOrders((prevState) => [
          ...prevState.filter((orderElement) => orderElement.id !== order.id),
          response.data,
        ]);

        message.success(t("Value updated.", { value: t("Order") }));
      } catch (error) {
        handleError(error);
      }
      setLoadingUpdateOrder(false);
    },
  });

  return (
    <FormWrapper title={t("Value Update Form", { value: t("Order") })}>
      <form className="flex flex-column" onSubmit={formik.handleSubmit}>
        <div className="field flex flex-column">
          <label>{t("Created Date")}</label>
          <Calendar
            value={new Date(order.createdDateTime)}
            showTime
            hourFormat="24"
            disabled
            dateFormat="dd.mm.yy"
          />
        </div>

        <div className="flex flex-row">
          {order.type === "TABLE" && (
            <div className="field flex flex-column lg:w-6 lg:pr-1">
              <label>{t("Table")}*</label>
              <Dropdown
                name="tableId"
                value={formik.values.tableId}
                optionLabel="label"
                optionValue="value"
                options={[
                  { label: order.tableName, value: order.tableId },
                  ...emptyTables.map((table) => ({
                    label: table.name,
                    value: table.id,
                  })),
                ]}
                onChange={formik.handleChange}
                disabled={readOnly}
              />
            </div>
          )}
          <div className="field flex flex-column lg:w-6 lg:pl-1">
            <label>{t("Customer Count")}*</label>
            <InputNumber
              name="customerCount"
              value={formik.values.customerCount}
              onChange={(e) => formik.setFieldValue("customerCount", e.value)}
              invalid={formik.errors.customerCount}
              disabled={readOnly}
            />
            {formik.errors.customerCount && (
              <small className="p-error">{formik.errors.customerCount}</small>
            )}
          </div>
        </div>

        <div className="field flex flex-column">
          <label>{t("Note")}</label>
          <InputTextarea
            name="note"
            value={formik.values.note}
            onChange={formik.handleChange}
            rows={2}
            disabled={readOnly}
            invalid={formik.errors.note}
          />
          {formik.errors.note && (
            <small className="p-error">{formik.errors.note}</small>
          )}
        </div>
        {order.type === "DELIVERY" && (
          <div className="field flex flex-column">
            <label>{t("Delivery Address")}*</label>
            <InputText
              name="deliveryAddress"
              value={formik.values.deliveryAddress}
              onChange={formik.handleChange}
              invalid={formik.errors.deliveryAddress}
              disabled={readOnly}
            />
            {formik.errors.deliveryAddress && (
              <small className="p-error">{formik.errors.deliveryAddress}</small>
            )}
          </div>
        )}
        <Button
          type="submit"
          label={t("Update")}
          loading={loadingUpdateOrder}
          disabled={readOnly}
        />
      </form>
    </FormWrapper>
  );
};

export default OrderUpdateForm;
