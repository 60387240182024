import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListCategories } from "../../apis/categoryApis";
import { useEffect, useState } from "react";
import { createProduct } from "../../apis/productApis";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadImage from "../../components/common/UploadImage";

const ProductCreatePage = () => {
  const [categories, setCategories] = useState([]);

  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingCreateProduct, setLoadingCreateProduct] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const categoryMap = new Map();
  const parentCategoryIdAndSubCategoriesMap = new Map();
  parentCategoryIdAndSubCategoriesMap.set(null, []);
  categories.forEach((category) => {
    categoryMap.set(category.id, category.name);

    if (
      parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId) ===
      undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        category,
      ]);
    } else {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        ...parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId),
        category,
      ]);
    }

    if (
      category.parentCategoryId === null &&
      parentCategoryIdAndSubCategoriesMap.get(category.id) === undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.id, []);
    }
  });

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingSearchListCategories(true);
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  const onFinish = async (values) => {
    const body = {
      name: values.productName,
      price: values.price,
      position: values.position,
      status: values.status,
      categoryId: values.categoryId,
      description: values.description ? values.description : null,
      image: image ? image.split("base64,")[1] : null,
      showInQrMenu: values.showInQrMenu,
    };

    setLoadingCreateProduct(true);
    try {
      await createProduct(body);
      message.success(t("Value created.", { value: t("Product") }));
      if (values.quickAdd) {
        form.resetFields([
          "productName",
          "price",
          "description",
          "showInQrMenu",
        ]);
        setLoadingCreateProduct(false);
      } else {
        navigate("/products/list");
      }
    } catch (error) {
      handleError(error);
      setLoadingCreateProduct(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Card
        size="small"
        title={t("Value Create Form", { value: t("Product") })}
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="categoryId"
              label={t("Category")}
              rules={[
                {
                  required: true,
                  message: t("Please select value!", { value: t("Category") }),
                },
              ]}
            >
              <Select
                loading={loadingSearchListCategories}
                options={parentCategoryIdAndSubCategoriesMap
                  .get(null)
                  .map((parentCategory) => {
                    return {
                      label: parentCategory.name,
                      options: parentCategoryIdAndSubCategoriesMap
                        .get(parentCategory.id)
                        .map((subCategory) => ({
                          label: subCategory.name,
                          value: subCategory.id,
                        })),
                    };
                  })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="productName"
              label={t("Value Name", { value: t("Product") })}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Value Name", { value: t("Product") }),
                  }),
                },
                {
                  min: 2,
                  message: t("Minimum value characters!", { value: 2 }),
                },
                {
                  max: 40,
                  message: t("Maximum value characters!", { value: 40 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="price"
              label={t("Product Price")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Product Price"),
                  }),
                  type: "number",
                },
                {
                  min: 0,
                  message: t("Minimum value!", { value: 0 }),
                  type: "number",
                },
                {
                  max: 9999.99,
                  message: t("Maximum value!", { value: 9999.99 }),
                  type: "number",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                addonAfter="TL"
                type="number"
                precision={2}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="position"
              label={t("Value Position", { value: t("Product") })}
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Value Position", { value: t("Product") }),
                  }),
                },
                {
                  type: "number",
                  min: 0,
                  message: t("Minimum value!", { value: 0 }),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="description" label={t("Description")}>
              <Input.TextArea maxLength={250} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="status"
              label={t("Status")}
              initialValue="ACTIVE"
              rules={[
                {
                  required: true,
                  message: t("Please select value!", { value: "Status" }),
                },
              ]}
            >
              <Select>
                <Select.Option value="ACTIVE">{t("Active")}</Select.Option>
                <Select.Option value="PASSIVE">{t("Passive")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={t("Image")}>
              <UploadImage setImage={setImage} setLoading={setLoadingImage} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="showInQrMenu"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>{t("Show in QR menu")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="quickAdd" valuePropName="checked">
          <Checkbox>{t("Quick add")}</Checkbox>
        </Form.Item>
        <Form.Item style={{ marginBottom: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={
              loadingSearchListCategories ||
              loadingCreateProduct ||
              loadingImage
            }
          >
            {t("Create")}
          </Button>
        </Form.Item>
        <Alert
          message={t("Product can be added to only subcategories.")}
          type="info"
          showIcon
        />
      </Card>
    </Form>
  );
};

export default ProductCreatePage;
