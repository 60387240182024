import { Button, Card, Col, Form, Input, Row, Select, message } from "antd";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../apis/userApis";
import { TENANT_ADMIN, TENANT_WAITER } from "../../common/Constants";
import { useTranslation } from "react-i18next";

const UserCreateForm = (props) => {
  const { tenantId } = props;

  const [loadingCreateUser, setLoadingCreateUser] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const onFinish = async (values) => {
    const body = {
      username: values.username,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      contactEmail: values.contactEmail,
      contactNumber: values.contactNumberPrefix + values.contactNumber,
      role: values.role,
    };

    setLoadingCreateUser(true);
    try {
      await createUser(body, tenantId);
      message.success(t("Value created.", { value: t("User") }));
      navigate(tenantId ? `/tenants/${tenantId}/users/list` : "/users/list");
    } catch (error) {
      handleError(error);
      setLoadingCreateUser(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Card size="small" title={t("Value Create Form", { value: t("User") })}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="username"
              label={t("Username")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", { value: t("Username") }),
                },
                {
                  min: 4,
                  message: t("Minimum value characters!", { value: 4 }),
                },
                {
                  max: 20,
                  message: t("Maximum value characters!", { value: 20 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="password"
              label={t("Password")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", { value: t("Password") }),
                },
                {
                  min: 6,
                  message: t("Minimum value characters!", { value: 6 }),
                },
                {
                  max: 20,
                  message: t("Maximum value characters!", { value: 20 }),
                },
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="role"
                  label={t("Role")}
                  rules={[
                    {
                      required: true,
                      message: t("Please select value!", { value: t("Role") }),
                    },
                  ]}
                  initialValue={TENANT_ADMIN}
                >
                  <Select>
                    <Select.Option value={TENANT_ADMIN}>
                      {t("Admin")}
                    </Select.Option>
                    <Select.Option value={TENANT_WAITER}>
                      {t("Waiter")}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="firstName"
              label={t("First Name")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", { value: t("First Name") }),
                },
                {
                  min: 2,
                  message: t("Minimum value characters!", { value: 2 }),
                },
                {
                  max: 40,
                  message: t("Maximum value characters!", { value: 40 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="lastName"
              label={t("Last Name")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", { value: t("Last Name") }),
                },
                {
                  min: 2,
                  message: t("Minimum value characters!", { value: 2 }),
                },
                {
                  max: 40,
                  message: t("Maximum value characters!", { value: 40 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="contactEmail"
              label={t("Contact Email")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Contact Email"),
                  }),
                },
                {
                  pattern:
                    "^[\\w!#$%&’*+/=?`{|}~^-]+(?:\\.[\\w!#$%&’*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$",
                  message: t("Value format is not valid!", {
                    value: t("Contact Email"),
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="contactNumber"
              label={t("Contact Number")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Contact Number"),
                  }),
                },
                {
                  pattern: "5[0-9]{9}",
                  message: t("Value format is not valid!", {
                    value: t("Contact Number"),
                  }),
                },
              ]}
            >
              <Input
                addonBefore={
                  <Form.Item
                    name="contactNumberPrefix"
                    noStyle
                    initialValue="+90"
                  >
                    <Select
                      style={{
                        width: 70,
                      }}
                    >
                      <Select.Option value="+90">+90</Select.Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item style={{ marginBottom: 8 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingCreateUser}
              >
                {t("Create")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default UserCreateForm;
