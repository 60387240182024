import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import { Link, useSearchParams } from "react-router-dom";
import { searchListTables } from "../../apis/tableApis";
import { searchPageOrders } from "../../apis/orderApis";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  validateAndGetDateParam,
  validateAndGetIdParam,
  validateAndGetMultipleSelectParam,
} from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";

const OrderListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tables, setTables] = useState([]);
  const [orderPage, setOrderPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchListTables, setLoadingSearchListTables] = useState(true);
  const [loadingSearchPageOrders, setLoadingSearchPageOrders] = useState(true);

  const handleError = useErrorHandler();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const tableMap = new Map();
  tables.forEach((table) => {
    tableMap.set(table.id, table);
  });

  const pageParam = searchParams.get("page");
  const sizeParam = searchParams.get("size");
  const createdDateTimeMinParam = searchParams.get("createdDateTimeMin");
  const createdDateTimeMaxParam = searchParams.get("createdDateTimeMax");
  const tableIdParam = searchParams.get("tableId");
  const statusListParam = searchParams.get("statusList");
  const createdDateTimeMin = validateAndGetDateParam(createdDateTimeMinParam);
  const createdDateTimeMax = validateAndGetDateParam(createdDateTimeMaxParam);
  const tableId = validateAndGetIdParam(tableIdParam, tableMap);
  const statusList = validateAndGetMultipleSelectParam(
    statusListParam,
    ["OPEN", "CLOSE"],
    ["OPEN", "CLOSE"]
  );

  const readyForSearchOrders = !tableIdParam || !loadingSearchListTables;

  useEffect(() => {
    const fetchTables = async () => {
      const tableSearchDTO = {
        statusList: ["ACTIVE", "PASSIVE"],
      };
      try {
        const response = await searchListTables(tableSearchDTO);
        setTables(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListTables(false);
    };

    fetchTables();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      const orderSearchDTO = {
        createdDateTimeMin: createdDateTimeMin
          ? createdDateTimeMin.format("YYYY-MM-DD HH:mm:ss")
          : null,
        createdDateTimeMax: createdDateTimeMax
          ? createdDateTimeMax.format("YYYY-MM-DD HH:mm:ss")
          : null,
        statusList,
        tableId,
      };
      setLoadingSearchPageOrders(true);
      try {
        const response = await searchPageOrders(
          pageParam - 1,
          sizeParam,
          orderSearchDTO
        );
        setOrderPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchPageOrders(false);
    };

    if (readyForSearchOrders) {
      fetchOrders();
    }
  }, [
    readyForSearchOrders,
    pageParam,
    sizeParam,
    retriggerValue,
    createdDateTimeMinParam,
    createdDateTimeMaxParam,
    tableIdParam,
    statusListParam,
  ]);

  const onFinish = (values) => {
    if (values.createdDateTimeMin) {
      searchParams.set(
        "createdDateTimeMin",
        dayjs(values.createdDateTimeMin).second(0)
      );
    } else {
      searchParams.delete("createdDateTimeMin");
    }
    if (values.createdDateTimeMax) {
      searchParams.set(
        "createdDateTimeMax",
        dayjs(values.createdDateTimeMax).second(59)
      );
    } else {
      searchParams.delete("createdDateTimeMax");
    }

    if (values.tableId) {
      searchParams.set("tableId", values.tableId);
    } else {
      searchParams.delete("tableId");
    }

    searchParams.set("statusList", values.statusList);

    searchParams.set("page", 1);

    setSearchParams(searchParams);
    setRetriggerValue((prevState) => prevState + 1);
  };

  const columns = [
    {
      title: t("Value Name", { value: t("Table") }),
      key: "tableName",
      render: (_, record) =>
        record.tableName
          ? record.tableName
          : record.type === "TAKEAWAY"
          ? t("Takeaway")
          : t("Delivery"),
    },
    {
      title: t("Customer Count"),
      key: "customerCount",
      dataIndex: "customerCount",
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) =>
        record.status === "OPEN" ? t("Open") : t("Close"),
    },
    {
      title: t("Created Date"),
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      render: (_, record) =>
        dayjs(record.createdDateTime).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/orders/${record.id}`}>{t("Details")}</Link>
        </Space>
      ),
    },
  ];

  return (
    readyForSearchOrders && (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            size="small"
            title={t("Value Search Filter Form", { value: t("Order") })}
          >
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="createdDateTimeMin"
                    label={t("Minimum Created Date")}
                    initialValue={createdDateTimeMin}
                  >
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      style={{ width: "100%" }}
                      showTime={{
                        format: "HH:mm",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="createdDateTimeMax"
                    label={t("Maximum Created Date")}
                    initialValue={createdDateTimeMax}
                  >
                    <DatePicker
                      format="DD.MM.YYYY HH:mm"
                      style={{ width: "100%" }}
                      showTime={{
                        format: "HH:mm",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="tableId"
                    label={t("Table")}
                    initialValue={tableId}
                  >
                    <Select allowClear>
                      {tables.map((table) => {
                        return (
                          <Select.Option key={table.id} value={table.id}>
                            {table.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="statusList"
                    label={t("Status")}
                    initialValue={statusList}
                    rules={[
                      {
                        required: true,
                        message: t("Please select value!", {
                          value: t("Table"),
                        }),
                      },
                    ]}
                  >
                    <Select mode="multiple">
                      <Select.Option value="OPEN">{t("Open")}</Select.Option>
                      <Select.Option value="CLOSE">{t("Close")}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item style={{ marginBottom: 8 }}>
                    <Row gutter={16}>
                      <Col style={{ flexGrow: 1 }}></Col>
                      <Col>
                        <Button
                          disabled={
                            loadingSearchListTables || loadingSearchPageOrders
                          }
                          onClick={() => {
                            form.setFieldValue("createdDateTimeMin", null);
                            form.setFieldValue("createdDateTimeMax", null);
                            form.setFieldValue("tableId", undefined);
                            form.setFieldValue("statusList", ["OPEN", "CLOSE"]);
                          }}
                        >
                          {t("Reset")}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={
                            loadingSearchListTables || loadingSearchPageOrders
                          }
                        >
                          {t("Filter")}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24}>
          <Table
            bordered
            columns={columns}
            dataSource={orderPage.content}
            pagination={{
              showSizeChanger: true,
              current: orderPage.number + 1,
              pageSize: orderPage.size,
              total: orderPage.totalElements,
            }}
            rowKey="id"
            onChange={(pagination, filters, sorter) => {
              searchParams.set("page", pagination.current);
              searchParams.set("size", pagination.pageSize);
              setSearchParams(searchParams);
            }}
            loading={loadingSearchPageOrders}
          />
        </Col>
      </Row>
    )
  );
};

export default OrderListPage;
