import { useEffect, useState } from "react";
import {
  createBalanceAccount,
  searchListBalanceAccounts,
} from "../../apis/balanceAccountApis";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
  Table,
} from "antd";
import { t } from "i18next";
import {
  createBalanceAccountTransaction,
  getBalanceAccountTransactionListByBalanceAccountId,
} from "../../apis/balanceAccountTransactionApis";
import { formatTL } from "../../common/CommonUtils";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";

const BalanceManagementPage = () => {
  const [balanceAccountList, setBalanceAccountList] = useState([]);
  const [selectedBalanceAccount, setSelectedBalanceAccount] = useState(null);
  const [balanceAccountTransactionList, setBalanceAccountTransactionList] =
    useState([]);

  const [
    loadingSearchListBalanceAccounts,
    setLoadingSearchListBalanceAccounts,
  ] = useState(true);
  const [
    loadingGetBalanceAccountTransactionListByBalanceAccountId,
    setLoadingGetBalanceAccountTransactionListByBalanceAccountId,
  ] = useState(false);
  const [loadingCreateBalanceAccount, setLoadingCreateBalanceAccount] =
    useState(false);
  const [
    loadingCreateBalanceAccountTransaction,
    setLoadingCreateBalanceAccountTransaction,
  ] = useState(false);

  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);

  const handleError = useErrorHandler();

  const [form] = Form.useForm();
  const [formAccount] = Form.useForm();

  const balanceAccountMap = new Map();
  balanceAccountList.forEach((balanceAccount) => {
    balanceAccountMap.set(balanceAccount.id, balanceAccount);
  });

  useEffect(() => {
    const fetchBalanceAccounts = async () => {
      try {
        const response = await searchListBalanceAccounts();
        setBalanceAccountList(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListBalanceAccounts(false);
    };

    fetchBalanceAccounts();
  }, []);

  useEffect(() => {
    const fetchBalanceAccountTransactions = async () => {
      setLoadingGetBalanceAccountTransactionListByBalanceAccountId(true);
      try {
        const response =
          await getBalanceAccountTransactionListByBalanceAccountId(
            selectedBalanceAccount.id
          );
        setBalanceAccountTransactionList(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingGetBalanceAccountTransactionListByBalanceAccountId(false);
    };

    if (selectedBalanceAccount !== null) {
      fetchBalanceAccountTransactions();
    }
  }, [selectedBalanceAccount]);

  const filterOption = (input, option) =>
    (option?.label ?? "")
      .toLocaleLowerCase("tr-TR")
      .includes(input.toLocaleLowerCase("tr-TR"));

  const onClickReset = () => {
    setSelectedBalanceAccount(null);
    setBalanceAccountTransactionList([]);
  };

  const onFinishTransactionForm = async (values) => {
    const body = {
      amount: values.amount,
      description: values.description ? values.description : null,
    };
    setLoadingCreateBalanceAccountTransaction(true);
    try {
      const response = await createBalanceAccountTransaction(
        selectedBalanceAccount.id,
        body
      );
      setBalanceAccountTransactionList([
        response.data,
        ...balanceAccountTransactionList,
      ]);
      form.resetFields(["amount", "description"]);
    } catch (error) {
      handleError(error);
    }
    setLoadingCreateBalanceAccountTransaction(false);
    setTransactionModalOpen(false);
  };

  const onFinishAccountForm = async (values) => {
    const body = {
      name: values.balanceAccountName,
      type: "CUSTOMER",
    };
    setLoadingCreateBalanceAccount(true);
    try {
      const response = await createBalanceAccount(body);
      setBalanceAccountList([response.data, ...balanceAccountList]);
      formAccount.resetFields(["balanceAccountName"]);
    } catch (error) {
      handleError(error);
    }
    setLoadingCreateBalanceAccount(false);
    setAccountModalOpen(false);
  };

  const columns = [
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Amount"),
      key: "amount",
      render: (_, record) => formatTL(record.amount),
    },
    {
      title: t("Created Date"),
      key: "createdDate",
      render: (_, record) =>
        dayjs(record.createdDateTime).format("DD.MM.YYYY HH:mm"),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card
          size="small"
          title={
            <div style={{ display: "flex" }}>
              <div
                style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
              >
                {t("Balance Account")}
              </div>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                disabled={selectedBalanceAccount !== null}
                onClick={() => {
                  setAccountModalOpen(true);
                }}
              >
                {t("Add Balance Account")}
              </Button>
              <Modal
                open={accountModalOpen}
                footer={null}
                onCancel={() => {
                  setAccountModalOpen(false);
                }}
                closable={!loadingCreateBalanceAccount}
                maskClosable={!loadingCreateBalanceAccount}
              >
                <Form
                  layout="vertical"
                  onFinish={onFinishAccountForm}
                  form={formAccount}
                >
                  <Card
                    size="small"
                    title={t("Value Create Form", {
                      value: t("Balance Account"),
                    })}
                  >
                    <Row gutter={16}>
                      <Col xs={24}>
                        <Form.Item
                          name="balanceAccountName"
                          label={t("Name")}
                          rules={[
                            {
                              required: true,
                              message: t("Please input value!", {
                                value: t("Value Name", {
                                  value: t("Balance Account"),
                                }),
                              }),
                            },
                            {
                              min: 1,
                              message: t("Minimum value characters!", {
                                value: 1,
                              }),
                            },
                            {
                              max: 50,
                              message: t("Maximum value characters!", {
                                value: 50,
                              }),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item style={{ marginBottom: 8 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingCreateBalanceAccount}
                      >
                        {t("Create")}
                      </Button>
                    </Form.Item>
                  </Card>
                </Form>
              </Modal>
            </div>
          }
        >
          <Select
            loading={loadingSearchListBalanceAccounts}
            disabled={selectedBalanceAccount !== null}
            showSearch
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedBalanceAccount(balanceAccountMap.get(value));
            }}
            options={balanceAccountList.map((balanceAccount) => ({
              value: balanceAccount.id,
              label: balanceAccount.name,
            }))}
            value={
              selectedBalanceAccount !== null
                ? selectedBalanceAccount.name
                : null
            }
            filterOption={filterOption}
          />
          {selectedBalanceAccount != null && (
            <Button
              style={{ marginTop: 8 }}
              onClick={() => {
                onClickReset();
              }}
            >
              {t("Reset")}
            </Button>
          )}
        </Card>
      </Col>

      <Col xs={6}>
        <Card>
          <Statistic
            loading={loadingGetBalanceAccountTransactionListByBalanceAccountId}
            title={t("Total")}
            value={formatTL(
              balanceAccountTransactionList.reduce(
                (sum, transaction) => sum + transaction.amount,
                0
              )
            )}
          />
        </Card>
      </Col>
      <Col xs={18}>
        {loadingGetBalanceAccountTransactionListByBalanceAccountId ? (
          <Spin />
        ) : (
          <>
            {selectedBalanceAccount !== null && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={{ position: "absolute", bottom: 0, right: 0 }}
                onClick={() => {
                  setTransactionModalOpen(true);
                }}
              >
                {t("Add Transaction")}
              </Button>
            )}
            {selectedBalanceAccount !== null && (
              <Modal
                open={transactionModalOpen}
                footer={null}
                onCancel={() => {
                  setTransactionModalOpen(false);
                }}
                closable={!loadingCreateBalanceAccountTransaction}
                maskClosable={!loadingCreateBalanceAccountTransaction}
              >
                <Form
                  layout="vertical"
                  onFinish={onFinishTransactionForm}
                  form={form}
                >
                  <Card
                    size="small"
                    title={t("Value Create Form", { value: t("Transaction") })}
                  >
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="amount"
                          label={t("Amount")}
                          rules={[
                            {
                              required: true,
                              message: t("Please input value!", {
                                value: t("Amount"),
                              }),
                              type: "number",
                            },
                            {
                              min: -999999.99,
                              message: t("Minimum value!", {
                                value: -999999.99,
                              }),
                              type: "number",
                            },
                            {
                              max: 999999.99,
                              message: t("Maximum value!", {
                                value: 999999.99,
                              }),
                              type: "number",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            addonAfter="TL"
                            type="number"
                            precision={2}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="description"
                          label={t("Description")}
                          rules={[
                            {
                              max: 200,
                              message: t("Maximum value characters!", {
                                value: 200,
                              }),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item style={{ marginBottom: 8 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingCreateBalanceAccountTransaction}
                      >
                        {t("Create")}
                      </Button>
                    </Form.Item>
                  </Card>
                </Form>
              </Modal>
            )}
          </>
        )}
      </Col>
      <Col xs={24}>
        <Table
          scroll={{ x: "100%" }}
          bordered
          columns={columns}
          dataSource={balanceAccountTransactionList}
          pagination={{
            showSizeChanger: true,
            // current: tablePage.number + 1,
            // pageSize: tablePage.size,
            total: balanceAccountTransactionList.length,
          }}
          rowKey="id"
          // onChange={(pagination, filters, sorter) => {
          //   searchParams.set("page", pagination.current);
          //   searchParams.set("size", pagination.pageSize);
          //   setSearchParams(searchParams);
          // }}
          loading={loadingGetBalanceAccountTransactionListByBalanceAccountId}
        />
      </Col>
    </Row>
  );
};

export default BalanceManagementPage;
