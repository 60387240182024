import { useRecoilValue } from "recoil";
import { authorizationTokenState } from "../../recoil/atoms";
import {
  deleteOrderProduct,
  updateOrderProduct,
} from "../../apis/orderProductApis";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import dayjs from "dayjs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import DashedDivider from "../common/DashedDivider";
import { Button } from "primereact/button";
import { t } from "i18next";

const Bill = (props) => {
  const {
    order,
    orderProducts,
    setOrderProducts,
    payments,
    readOnly,
    printable,
    previewMode,
    billPrintSettings,
  } = props;

  const authorizationToken = useRecoilValue(authorizationTokenState);

  const totalPrice =
    orderProducts.reduce(
      (sum, orderProduct) =>
        sum + orderProduct.quantity * orderProduct.productPrice * 100,
      0
    ) / 100;

  const paidPrice = payments
    ? payments.reduce((sum, payment) => sum + payment.amount * 100, 0) / 100
    : 0;

  return (
    <div className="h-full w-full flex flex-column">
      <div className={printable ? "" : "h-full overflow-y-auto mb-1"}>
        <DataTable
          className="w-full"
          pt={
            printable
              ? {
                  column: {
                    bodyCell: { className: "border-white text-sm p-1" },
                    headerTitle: { className: "w-full" },
                    headerCell: {
                      className: "border-white bg-white text-sm p-1",
                    },
                  },
                }
              : {}
          }
          value={orderProducts}
          showHeaders={printable ? true : false}
          showGridlines
          size="small"
          headerColumnGroup={
            printable && (
              <ColumnGroup>
                {billPrintSettings.showLogo && (
                  <Row>
                    <Column
                      headerClassName="py-0"
                      align="center"
                      header={
                        <img
                          width={140}
                          src={
                            previewMode
                              ? billPrintSettings.logoImage
                              : `/images/tenants/${authorizationToken.tenant.code}/bill-print-settings/logo/${billPrintSettings.logoImageFileName}`
                          }
                        />
                      }
                      colSpan={3}
                    />
                  </Row>
                )}
                {billPrintSettings.showBusinessName && (
                  <Row>
                    <Column
                      align="center"
                      header={billPrintSettings.businessName}
                      colSpan={3}
                    />
                  </Row>
                )}
                {billPrintSettings.showBusinessAddress && (
                  <Row>
                    <Column
                      align="center"
                      header={
                        <div>
                          {billPrintSettings.businessAddress}
                          <br />
                          {`${billPrintSettings.businessCity}/${billPrintSettings.businessDistrict}`}
                        </div>
                      }
                      colSpan={3}
                    />
                  </Row>
                )}
                <Row>
                  <Column
                    header={
                      <div>
                        <div>
                          {t("Date")}:{" "}
                          {dayjs(order.createdDateTime).format("DD.MM.YYYY")}
                        </div>
                        <div>
                          {t("Time")}:{" "}
                          {dayjs(order.createdDateTime).format("HH:mm")}
                        </div>
                        {billPrintSettings.showNote && order.note && (
                          <div>{`${t("Note")}: ${order.note}`}</div>
                        )}
                        {order.type === "DELIVERY" && (
                          <div>{`${t("Address")}: ${
                            order.deliveryAddress
                          }`}</div>
                        )}
                      </div>
                    }
                    colSpan={3}
                  />
                </Row>
                <Row>
                  <Column
                    headerClassName="py-0"
                    header={<DashedDivider />}
                    colSpan={3}
                  />
                </Row>
              </ColumnGroup>
            )
          }
          emptyMessage={t("Empty")}
        >
          <Column field="productName" className="w-full" />
          <Column
            body={(orderProduct) =>
              `${orderProduct.quantity}x${orderProduct.productPrice}`
            }
          />
          <Column
            body={(orderProduct) =>
              orderProduct.quantity * orderProduct.productPrice
            }
          />
          {!printable && !readOnly && order.status === "OPEN" && (
            <Column
              body={(orderProduct) => (
                <Actions
                  orderProduct={orderProduct}
                  setOrderProducts={setOrderProducts}
                />
              )}
            />
          )}
        </DataTable>
      </div>

      <DataTable
        pt={
          printable
            ? {
                column: {
                  bodyCell: { className: "p-0 border-white" },
                  footerCell: {
                    className: "border-white bg-white text-sm p-1",
                  },
                },
              }
            : {
                column: {
                  bodyCell: { className: "p-0 border-white" },
                },
              }
        }
        emptyMessage={<></>}
        size="small"
        showGridlines={printable ? false : true}
        footerColumnGroup={
          <ColumnGroup>
            {printable && (
              <Row>
                <Column
                  className="py-0 bg-white"
                  footer={<DashedDivider />}
                  colSpan={2}
                />
              </Row>
            )}
            <Row>
              <Column
                className={printable ? "bg-white w-full" : ""}
                footer={t("Total")}
              />
              <Column
                className={printable ? "bg-white" : ""}
                footer={totalPrice}
                align="right"
              />
            </Row>
            {!printable && (
              <Row>
                <Column className="w-full" footer={t("Paid")} />
                <Column footer={paidPrice} align="right" />
              </Row>
            )}
            {!printable && (
              <Row>
                <Column className="w-full" footer={t("Remaining")} />
                <Column
                  footer={(totalPrice * 100 - paidPrice * 100) / 100}
                  align="right"
                />
              </Row>
            )}
            {printable && billPrintSettings.showFooterMessage && (
              <Row>
                <Column
                  className="py-0 bg-white"
                  footer={<DashedDivider />}
                  colSpan={3}
                />
              </Row>
            )}
            {printable && billPrintSettings.showFooterMessage && (
              <Row>
                <Column
                  className="bg-white"
                  colSpan={3}
                  align="center"
                  footer={billPrintSettings.footerMessage}
                />
              </Row>
            )}
          </ColumnGroup>
        }
      ></DataTable>
    </div>
  );
};

export default Bill;

const Actions = (props) => {
  const { orderProduct, setOrderProducts } = props;
  // "+", "-", null
  const [loadingUpdateOrderProduct, setLoadingUpdateOrderProduct] =
    useState(null);
  const [loadingDeleteOrderProduct, setLoadingDeleteOrderProduct] =
    useState(false);

  const handleError = useErrorHandler();

  const onClickMinus = async (orderProduct) => {
    if (orderProduct.quantity > 1) {
      const body = {
        quantity: orderProduct.quantity - 1,
      };
      setLoadingUpdateOrderProduct("-");
      try {
        const response = await updateOrderProduct(orderProduct.id, body);
        setOrderProducts((prevState) => {
          const copyPrevState = [...prevState];
          const index = copyPrevState.findIndex(
            (orderProductItem) => orderProductItem.id === orderProduct.id
          );
          copyPrevState[index] = response.data;

          return copyPrevState;
        });
      } catch (error) {
        handleError(error);
      }
      setLoadingUpdateOrderProduct(null);
    } else {
      setLoadingDeleteOrderProduct(true);
      try {
        await deleteOrderProduct(orderProduct.id);
        setOrderProducts((prevState) =>
          prevState.filter(
            (orderProductItem) => orderProductItem.id !== orderProduct.id
          )
        );
      } catch (error) {
        handleError(error);
      }
      setLoadingDeleteOrderProduct(false);
    }
  };

  const onClickPlus = async (orderProduct) => {
    const body = {
      quantity: orderProduct.quantity + 1,
    };
    setLoadingUpdateOrderProduct("+");
    try {
      const response = await updateOrderProduct(orderProduct.id, body);
      setOrderProducts((prevState) => {
        const copyPrevState = [...prevState];
        const index = copyPrevState.findIndex(
          (orderProductItem) => orderProductItem.id === orderProduct.id
        );
        copyPrevState[index] = response.data;

        return copyPrevState;
      });
    } catch (error) {
      handleError(error);
    }
    setLoadingUpdateOrderProduct(null);
  };

  return (
    <div className="flex">
      <div className="mr-1">
        <Button
          className="w-2rem h-2rem"
          icon="pi pi-minus"
          outlined
          disabled={loadingUpdateOrderProduct === "+"}
          loading={
            loadingUpdateOrderProduct === "-" || loadingDeleteOrderProduct
          }
          onClick={() => {
            onClickMinus(orderProduct);
          }}
        />
      </div>
      <div className="ml-1">
        <Button
          className="w-2rem h-2rem"
          icon="pi pi-plus"
          outlined
          disabled={
            loadingUpdateOrderProduct === "-" || loadingDeleteOrderProduct
          }
          loading={loadingUpdateOrderProduct === "+"}
          onClick={() => {
            onClickPlus(orderProduct);
          }}
        />
      </div>
    </div>
  );
};
