import axios from "axios";

export const createTenant = (body) => {
  return axios.post("/api/1.0/tenants", body);
};

export const getTenant = (tenantId) => {
  return axios.get(`/api/1.0/tenants/${tenantId}`);
};

export const searchTenants = (page, size, tenantSearchDTO) => {
  const { name } = tenantSearchDTO;

  const params = new URLSearchParams();
  params.append("page", page);
  params.append("size", size);
  if (name) {
    params.append("name", name);
  }

  return axios.get(`/api/1.0/tenants/page?${params.toString()}`);
};
