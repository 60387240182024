import OrderUpdateForm from "./OrderUpdateForm";

const OrderTabContent = (props) => {
  const {
    readOnly,
    emptyTables,
    order,
    setOrders,
    setOrderProducts,
    setPayments,
  } = props;

  return (
    <OrderUpdateForm
      readOnly={readOnly}
      emptyTables={emptyTables}
      order={order}
      setOrders={setOrders}
      setOrderProducts={setOrderProducts}
      setPayments={setPayments}
    />
  );
};

export default OrderTabContent;
