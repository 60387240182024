import { Button, Card, Col, Form, Input, Row, Space, Table } from "antd";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { searchTenants } from "../../apis/tenantApis";
import { validateAndGetTextParam } from "../../common/CommonUtils";

const TenantListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tenantPage, setTenantPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchTenants, setLoadingSearchTenants] = useState(true);

  const handleError = useErrorHandler();

  const [form] = Form.useForm();

  const pageParam = searchParams.get("page");
  const sizeParam = searchParams.get("size");
  const nameParam = searchParams.get("name");
  const name = validateAndGetTextParam(nameParam, 50);

  useEffect(() => {
    const fetchTenants = async () => {
      const tenantSearchDTO = {
        name,
      };
      setLoadingSearchTenants(true);
      try {
        const response = await searchTenants(
          pageParam - 1,
          sizeParam,
          tenantSearchDTO
        );
        setTenantPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchTenants(false);
    };

    fetchTenants();
  }, [pageParam, sizeParam, nameParam, retriggerValue]);

  const onFinish = (values) => {
    if (values.tenantName) {
      searchParams.set("name", values.tenantName);
    } else {
      searchParams.delete("name");
    }

    searchParams.set("page", 1);

    setSearchParams(searchParams);

    setRetriggerValue((prevState) => prevState + 1);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City/District",
      key: "city/district",
      render: (_, record) => `${record.city}/${record.district}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/tenants/${record.id}/users/list`}>Users</Link>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card size="small" title="Tenant Search Filter Form">
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="tenantName"
                  label="Name"
                  initialValue={name}
                  rules={[
                    {
                      max: 50,
                      message: "Maximum 50 characters!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Row gutter={16}>
                    <Col style={{ flexGrow: 1 }}></Col>
                    <Col>
                      <Button
                        disabled={loadingSearchTenants}
                        onClick={() => {
                          form.setFieldValue("tenantName", undefined);
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingSearchTenants}
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col xs={24}>
        <Table
          scroll={{ x: "100%" }}
          bordered
          columns={columns}
          dataSource={tenantPage.content}
          pagination={{
            showSizeChanger: true,
            current: tenantPage.number + 1,
            pageSize: tenantPage.size,
            total: tenantPage.totalElements,
          }}
          rowKey="id"
          onChange={(pagination, filters, sorter) => {
            searchParams.set("page", pagination.current);
            searchParams.set("size", pagination.pageSize);
            setSearchParams(searchParams);
          }}
          loading={loadingSearchTenants}
        />
      </Col>
    </Row>
  );
};

export default TenantListPage;
