import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { createTable } from "../../apis/tableApis";
import { useTranslation } from "react-i18next";

const TableCreatePage = () => {
  const [loadingCreateTable, setLoadingCreateTable] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const onFinish = async (values) => {
    const body = {
      name: values.tableName,
      capacity: values.capacity,
      position: values.position,
      status: values.status,
    };

    setLoadingCreateTable(true);
    try {
      await createTable(body);
      message.success(t("Value created.", { value: t("Table") }));
      if (values.quickAdd) {
        form.resetFields(["tableName", "capacity", "position", "status"]);
        setLoadingCreateTable(false);
      } else {
        navigate("/tables/list");
      }
    } catch (error) {
      handleError(error);
      setLoadingCreateTable(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Card size="small" title={t("Value Create Form", { value: t("Table") })}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="tableName"
              label={t("Value Name", { value: t("Table") })}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Value Name", { value: t("Table") }),
                  }),
                },
                {
                  min: 1,
                  message: t("Minimum value characters!", { value: 1 }),
                },
                {
                  max: 10,
                  message: t("Maximum value characters!", { value: 10 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="capacity"
              label={t("Table Capacity")}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Table Capacity"),
                  }),
                },
                {
                  min: 1,
                  message: t("Minimum value!", { value: 1 }),
                  type: "number",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="position"
              label={t("Value Position", { value: t("Table") })}
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Value Position", { value: t("Table") }),
                  }),
                },
                {
                  type: "number",
                  min: 0,
                  message: t("Minimum value!", { value: 0 }),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="status"
              label={t("Status")}
              initialValue="ACTIVE"
              rules={[
                {
                  required: true,
                  message: t("Please select value!", { value: "Status" }),
                },
              ]}
            >
              <Select>
                <Select.Option value="ACTIVE">{t("Active")}</Select.Option>
                <Select.Option value="PASSIVE">{t("Passive")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="quickAdd" valuePropName="checked">
          <Checkbox>{t("Quick add")}</Checkbox>
        </Form.Item>
        <Form.Item style={{ marginBottom: 8 }}>
          <Button type="primary" htmlType="submit" loading={loadingCreateTable}>
            {t("Create")}
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default TableCreatePage;
