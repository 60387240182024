import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { createCategory, searchListCategories } from "../../apis/categoryApis";
import { useTranslation } from "react-i18next";

const CategoryCreatePage = () => {
  const [categories, setCategories] = useState([]);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingCreateCategory, setLoadingCreateCategory] = useState(false);

  const handleError = useErrorHandler();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await searchListCategories({});
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  const onFinish = async (values) => {
    const body = {
      parentCategoryId: values.parentCategoryId
        ? values.parentCategoryId
        : null,
      name: values.categoryName,
      position: values.position,
      showInQrMenu: values.showInQrMenu,
    };

    setLoadingCreateCategory(true);
    try {
      const response = await createCategory(body);
      message.success(t("Value created.", { value: t("Category") }));
      if (values.quickAdd) {
        form.resetFields(["parentCategoryId", "categoryName", "position"]);
        setCategories((prevState) => [...prevState, response.data]);
        setLoadingCreateCategory(false);
      } else {
        navigate("/categories/list");
      }
    } catch (error) {
      handleError(error);
      setLoadingCreateCategory(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Card
        size="small"
        title={t("Value Create Form", { value: t("Category") })}
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="parentCategoryId"
                  label={t("Value Name", { value: t("Parent Category") })}
                >
                  <Select
                    allowClear
                    loading={loadingSearchListCategories}
                    options={categories
                      .filter((category) => category.parentCategoryId === null)
                      .map((category) => ({
                        value: category.id,
                        label: category.name,
                      }))}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="categoryName"
              label={t("Value Name", { value: t("Category") })}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Value Name", { value: t("Category") }),
                  }),
                },
                {
                  min: 2,
                  message: t("Minimum value characters!", { value: 2 }),
                },
                {
                  max: 20,
                  message: t("Maximum value characters!", { value: 20 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="position"
              label={t("Value Position", { value: t("Category") })}
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: t("Please input value!", {
                    value: t("Value Position", { value: t("Category") }),
                  }),
                },
                {
                  type: "number",
                  min: 0,
                  message: t("Minimum value!", { value: 0 }),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="showInQrMenu"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>{t("Show in QR menu")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="quickAdd" valuePropName="checked">
          <Checkbox>{t("Quick add")}</Checkbox>
        </Form.Item>
        <Form.Item style={{ marginBottom: 8 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreateCategory}
          >
            {t("Create")}
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default CategoryCreatePage;
