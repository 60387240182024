import { message } from "antd";
import { useEffect, useRef } from "react";
import { getImageAsBase64 } from "../../apis/imageApis";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { t } from "i18next";
import { Card } from "primereact/card";
import { BlockUI } from "primereact/blockui";

const UploadImage = (props) => {
  const { formik, name, initialImageUrl, loading, setLoading } = props;

  const inputRef = useRef(null);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await getImageAsBase64(initialImageUrl);
      formik.setFieldValue(name, response);
      setLoading(false);
    };

    if (initialImageUrl) {
      fetchImage();
    } else {
      setLoading(false);
    }
  }, []);

  const validateFile = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t("You can only upload JPG/PNG file!"));
    }
    const isLt5M = file.size < 5 * 1024 * 1024;
    if (!isLt5M) {
      message.error(t("Image must smaller than 5MB!"));
    }

    return isJpgOrPng && isLt5M;
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      callback(reader.result);
    };
  };

  return loading || formik.values[name] ? (
    <BlockUI
      blocked={loading}
      template={<i className="pi pi-spin pi-spinner" />}
    >
      <Card
        pt={{
          body: { className: "p-0" },
          content: { className: "flex align-items-center p-1" },
        }}
      >
        <Image
          src={formik.values.logoImage}
          alt="logoImage"
          width="150"
          preview
        />
        <Button
          className="ml-2"
          icon="pi pi-trash"
          outlined
          onClick={() => {
            formik.setFieldValue(name, null);
          }}
        />
      </Card>
    </BlockUI>
  ) : (
    <div>
      <input
        ref={inputRef}
        className="hidden"
        type="file"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          if (validateFile(e.target.files[0])) {
            getBase64(e.target.files[0], (base64File) => {
              formik.setFieldValue(name, base64File);
            });
          }
        }}
      />
      <Button
        type="button"
        className="w-7rem h-2rem"
        label={t("Upload")}
        icon="pi pi-upload"
        onClick={(e) => {
          inputRef.current.click();
        }}
      />
    </div>
  );
};

export default UploadImage;
