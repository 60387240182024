import { useRef } from "react";
import { Button, Col, Descriptions, Row, Table, Tag } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { formatTL } from "../../common/CommonUtils";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import TrendyolYemekBill from "./TrendyolYemekBill";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  updateTrendyolYemekPackageStatusToDelivered,
  updateTrendyolYemekPackageStatusToInvoiced,
  updateTrendyolYemekPackageStatusToPicked,
  updateTrendyolYemekPackageStatusToShipped,
} from "../../apis/trendyolYemekOrderApis";

const TrendyolYemekTabContent = (props) => {
  const {
    loadingGetTrendyolYemekPackagePage,
    setLoadingGetTrendyolYemekPackagePage,
    trendyolYemekPackagePage,
    setTrendyolYemekPageParam,
    setTrendyolYemekPageRefreshCount,
  } = props;

  const handleError = useErrorHandler();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getPaymentTypeText = (payment) => {
    if (payment.paymentType === "PAY_WITH_CARD") {
      return "Kartla Ödeme";
    }
    if (payment.paymentType === "PAY_WITH_ON_DELIVERY") {
      return getPaymentTypeForPayWithOnDelivery(payment.onDelivery.paymentType);
    }
    return payment.paymentType;
  };

  const getPaymentTypeForPayWithOnDelivery = (paymentType) => {
    if (paymentType === "CARD") {
      return t("On Delivery") + " - " + t("Credit Card");
    }
    if (paymentType === "CASH") {
      return t("On Delivery") + " - " + t("Cash");
    }
    if (paymentType === "SODEXO_CARD") {
      return t("On Delivery") + " - " + "Pluxee Kart";
    }
    if (paymentType === "SODEXO_CODE") {
      return t("On Delivery") + " - " + "Pluxee Kod";
    }
    if (paymentType === "MULTINET_CARD") {
      return t("On Delivery") + " - " + "Multinet Kart";
    }
    if (paymentType === "MULTINET_CODE") {
      return t("On Delivery") + " - " + "Multinet Kod";
    }
    if (paymentType === "EDENRED_CARD") {
      return t("On Delivery") + " - " + "Edenred Kart";
    }
    if (paymentType === "EDENRED_CODE") {
      return t("On Delivery") + " - " + "Edenred Kod";
    }
    return paymentType;
  };

  const columns = [
    {
      title: t("Order Number"),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: t("Order Code"),
      dataIndex: "orderCode",
      key: "orderCode",
    },
    {
      title: t("Created Date"),
      key: "packageCreationDate",
      render: (_, record) =>
        dayjs(record.packageCreationDate).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: t("Value Name", { value: t("Customer") }),
      key: "customerName",
      render: (_, record) =>
        record.customer.firstName + " " + record.customer.lastName,
    },
    {
      title: t("Total Price"),
      key: "totalPrice",
      render: (_, record) => formatTL(record.totalPrice),
    },
    {
      title: t("Status"),
      key: "packageStatus",
      render: (_, record) => {
        if (record.packageStatus === "Created") {
          return (
            <div>
              <div>
                <Tag color="blue">{t("Waiting Approval")}</Tag>
              </div>
              <div style={{ marginTop: 4 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    onClickUpdateStatusToPicked(record.id);
                  }}
                >
                  {t("Confirm Order")}
                </Button>
              </div>
            </div>
          );
        }
        if (record.packageStatus === "Picking") {
          return (
            <div>
              <div>
                <Tag color="orange">{t("Getting Ready")}</Tag>
              </div>
              <div style={{ marginTop: 4 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    onClickUpdateStatusToInvoiced(record.id);
                  }}
                >
                  {t("Order Prepared")}
                </Button>
              </div>
            </div>
          );
        }
        if (record.packageStatus === "Invoiced") {
          return (
            <div>
              <div>
                <Tag color="orange">{t("Got Ready")}</Tag>
              </div>
              <div style={{ marginTop: 4 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    onClickUpdateStatusToShipped(record.id);
                  }}
                >
                  {t("Order On Delivery")}
                </Button>
              </div>
            </div>
          );
        }
        if (record.packageStatus === "Shipped") {
          return (
            <div>
              <div>
                <Tag color="orange">{t("On Delivery")}</Tag>
              </div>
              <div style={{ marginTop: 4 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    onClickUpdateStatusToDelivered(record.id);
                  }}
                >
                  {t("Order Delivered")}
                </Button>
              </div>
            </div>
          );
        }
        if (record.packageStatus === "Delivered") {
          return <Tag color="green">{t("Delivered")}</Tag>;
        }
        if (record.packageStatus === "Returned") {
          return <Tag color="red">{t("Couldn't Delivered")}</Tag>;
        }
        if (record.packageStatus === "Cancelled") {
          return <Tag color="red">{t("Cancelled")}</Tag>;
        }
        if (record.packageStatus === "UnSupplied") {
          return <Tag color="red">{t("Cancelled(Restaurant)")}</Tag>;
        }
      },
    },
  ];

  const onClickUpdateStatusToPicked = async (packageId) => {
    setLoadingGetTrendyolYemekPackagePage(true);
    try {
      await updateTrendyolYemekPackageStatusToPicked(packageId);
    } catch (error) {
      handleError(error);
    }
    setTrendyolYemekPageRefreshCount((prevState) => prevState + 1);
  };

  const onClickUpdateStatusToInvoiced = async (packageId) => {
    setLoadingGetTrendyolYemekPackagePage(true);
    try {
      await updateTrendyolYemekPackageStatusToInvoiced(packageId);
    } catch (error) {
      handleError(error);
    }
    setTrendyolYemekPageRefreshCount((prevState) => prevState + 1);
  };

  const onClickUpdateStatusToShipped = async (packageId) => {
    setLoadingGetTrendyolYemekPackagePage(true);
    try {
      await updateTrendyolYemekPackageStatusToShipped(packageId);
    } catch (error) {
      handleError(error);
    }
    setTrendyolYemekPageRefreshCount((prevState) => prevState + 1);
  };

  const onClickUpdateStatusToDelivered = async (packageId) => {
    setLoadingGetTrendyolYemekPackagePage(true);
    try {
      await updateTrendyolYemekPackageStatusToDelivered(packageId);
    } catch (error) {
      handleError(error);
    }
    setTrendyolYemekPageRefreshCount((prevState) => prevState + 1);
  };

  return (
    <Table
      scroll={{ x: "100%" }}
      rowKey="orderId"
      bordered
      columns={columns}
      loading={loadingGetTrendyolYemekPackagePage}
      pagination={{
        current: trendyolYemekPackagePage.page + 1,
        pageSize: 30,
        total: trendyolYemekPackagePage.totalCount,
      }}
      onChange={(pagination, filters, sorter) => {
        setTrendyolYemekPageParam(pagination.current - 1);
      }}
      expandable={{
        expandedRowRender: (record) => (
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <TrendyolYemekBill trendyolYemekPackage={record} />
            </Col>
            <Col xs={24}>
              <Descriptions
                items={[
                  {
                    key: "1",
                    label: t("Note"),
                    children: record.customerNote,
                  },
                  {
                    key: "2",
                    label: t("Address"),
                    children: (
                      <>
                        {record.address.address1 && record.address.address1}
                        {record.address.address2 &&
                          ` - ${record.address.address2}`}
                        {` - ${t("Floor/DoorNumber")}:${record.address.floor}/${
                          record.address.doorNumber
                        }`}
                        {record.address.addressDescription &&
                          ` - ${record.address.addressDescription}`}
                      </>
                    ),
                  },
                  {
                    key: "3",
                    label: t("Payment Type"),
                    children: getPaymentTypeText(record.payment),
                  },
                ]}
              />
            </Col>
            <Col xs={24}>
              <div
                style={{
                  display: "none",
                }}
              >
                <div ref={componentRef}>
                  <TrendyolYemekBill
                    trendyolYemekPackage={record}
                    printable
                    billPrintSettings={props.billPrintSettings}
                  />
                </div>
              </div>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#fa541c",
                  color: "rgb(255, 255, 255)",
                }}
                icon={<PrinterOutlined />}
                onClick={handlePrint}
              >
                {t("Print")}
              </Button>
            </Col>
          </Row>
        ),
      }}
      dataSource={trendyolYemekPackagePage.content}
    />
  );
};

export default TrendyolYemekTabContent;
