import { LockOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Result, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { getResetPasswordToken } from "../apis/resetPasswordTokenApis";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updatePassword } from "../apis/userApis";
import { useTranslation } from "react-i18next";

const UpdatePasswordPage = () => {
  const { token } = useParams();

  const [loadingGetResetPasswordToken, setLoadingGetResetPasswordToken] =
    useState(true);
  const [loadingUpdatePassword, setLoadingUpdatePassword] = useState(false);

  const [updateResult, setUpdateResult] = useState(null);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchResetPasswordToken = async () => {
      try {
        await getResetPasswordToken(token);
      } catch (error) {
        if (error.response.status === 404) {
          navigate("/login");
        } else {
          setUpdateResult("fail");
        }
      }
      setLoadingGetResetPasswordToken(false);
    };

    fetchResetPasswordToken();
  }, []);

  const onFinish = async (values) => {
    const body = {
      token,
      password: values.password,
    };
    setLoadingUpdatePassword(true);
    try {
      await updatePassword(body);
      setUpdateResult("success");
    } catch (error) {
      setUpdateResult("fail");
    }
    setLoadingUpdatePassword(false);
  };

  return (
    !loadingGetResetPasswordToken && (
      <Row justify="center" align="middle">
        <Col
          style={{
            marginTop: "128px",
            width: "100%",
            maxWidth: "440px",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          {updateResult === null ? (
            <Form
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Typography.Title level={3}>
                {t("Reset Password")}
              </Typography.Title>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("Please input value!", { value: t("Password") }),
                  },
                  {
                    min: 6,
                    message: t("Minimum value characters!", { value: 6 }),
                  },
                  {
                    max: 20,
                    message: t("Maximum value characters!", { value: 20 }),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder={t("Password")}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 8 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loadingUpdatePassword}
                >
                  {t("Update")}
                </Button>
              </Form.Item>
            </Form>
          ) : updateResult === "success" ? (
            <Result
              status="success"
              title={t("Value updated.", { value: t("Password") })}
              extra={
                <Link to="/login">
                  <Button>{t("Go Login")}</Button>
                </Link>
              }
            />
          ) : (
            <Result
              status="error"
              title="Oops!"
              subTitle={t(
                "Something went wrong. Please contact with developer."
              )}
              extra={
                <Link to="/login">
                  <Button>{t("Go Login")}</Button>
                </Link>
              }
            />
          )}
        </Col>
      </Row>
    )
  );
};

export default UpdatePasswordPage;
