import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import useErrorHandler from "../../hooks/useErrorHandler";
import { searchListCategories } from "../../apis/categoryApis";
import { deleteProduct, searchPageProducts } from "../../apis/productApis";
import {
  formatTL,
  validateAndGetIdParam,
  validateAndGetMultipleSelectParam,
  validateAndGetTextParam,
} from "../../common/CommonUtils";
import { useTranslation } from "react-i18next";

const ProductListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [form] = Form.useForm();

  const [categories, setCategories] = useState([]);
  const [productPage, setProductPage] = useState({
    number: 0,
    size: 20,
    totalElements: 0,
  });
  const [retriggerValue, setRetriggerValue] = useState(0);

  const [loadingSearchListCategories, setLoadingSearchListCategories] =
    useState(true);
  const [loadingSearchPageProducts, setLoadingSearchPageProducts] =
    useState(false);

  const handleError = useErrorHandler();

  const categoryMap = new Map();
  const childCategoryMap = new Map();
  const parentCategoryIdAndSubCategoriesMap = new Map();
  parentCategoryIdAndSubCategoriesMap.set(null, []);
  categories.forEach((category) => {
    categoryMap.set(category.id, category);

    if (category.parentCategoryId !== null) {
      childCategoryMap.set(category.id, category);
    }

    if (
      parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId) ===
      undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        category,
      ]);
    } else {
      parentCategoryIdAndSubCategoriesMap.set(category.parentCategoryId, [
        ...parentCategoryIdAndSubCategoriesMap.get(category.parentCategoryId),
        category,
      ]);
    }

    if (
      category.parentCategoryId === null &&
      parentCategoryIdAndSubCategoriesMap.get(category.id) === undefined
    ) {
      parentCategoryIdAndSubCategoriesMap.set(category.id, []);
    }
  });

  const pageParam = searchParams.get("page");
  const sizeParam = searchParams.get("size");
  const categoryIdParam = searchParams.get("categoryId");
  const nameParam = searchParams.get("name");
  const statusListParam = searchParams.get("statusList");
  const categoryId = validateAndGetIdParam(categoryIdParam, childCategoryMap);
  const name = validateAndGetTextParam(nameParam, 40);
  const statusList = validateAndGetMultipleSelectParam(
    statusListParam,
    ["ACTIVE", "PASSIVE"],
    ["ACTIVE", "PASSIVE"]
  );

  const { t } = useTranslation();

  const readyForSearchProducts =
    !loadingSearchListCategories && categoryId !== null;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await searchListCategories();
        setCategories(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchListCategories(false);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const productSearchDTO = {
        name,
        categoryId,
        statusList,
      };
      setLoadingSearchPageProducts(true);
      try {
        const response = await searchPageProducts(
          pageParam - 1,
          sizeParam,
          productSearchDTO
        );
        setProductPage(response.data);
      } catch (error) {
        handleError(error);
      }
      setLoadingSearchPageProducts(false);
    };

    if (readyForSearchProducts) {
      fetchProducts();
    }
  }, [
    readyForSearchProducts,
    pageParam,
    sizeParam,
    categoryIdParam,
    nameParam,
    statusListParam,
    retriggerValue,
  ]);

  const onFinish = (values) => {
    if (values.categoryId) {
      searchParams.set("categoryId", values.categoryId);
    } else {
      searchParams.delete("categoryId");
    }
    if (values.productName) {
      searchParams.set("name", values.productName);
    } else {
      searchParams.delete("name");
    }

    searchParams.set("statusList", values.statusList);

    searchParams.set("page", 1);

    setSearchParams(searchParams);
    setRetriggerValue((prevState) => prevState + 1);
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Price"),
      key: "price",
      render: (_, record) => formatTL(record.price),
    },
    {
      title: t("Status"),
      key: "status",
      render: (_, record) =>
        record.status === "ACTIVE" ? t("Active") : t("Passive"),
    },
    {
      title: t("Position"),
      dataIndex: "position",
      key: "position",
    },
    {
      title: t("Actions"),
      key: "actions",
      render: (_, record) => (
        <Space>
          <Space>
            <RouterLink to={`/products/${record.id}/update`}>
              {t("Update")}
            </RouterLink>
          </Space>
          <Space>
            <Popconfirm
              title={t("Delete Value", { value: t("Product") })}
              description={t("Are you sure to delete the value?", {
                value: t("Product"),
              })}
              onConfirm={async () => {
                try {
                  await deleteProduct(record.id);
                  message.success("Value deleted.", { value: record.name });
                  setRetriggerValue((prevState) => prevState + 1);
                } catch (error) {
                  handleError(error);
                }
              }}
              okText={t("Yes")}
              cancelText={t("No")}
            >
              <Typography.Link>{t("Delete")}</Typography.Link>
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    !loadingSearchListCategories && (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            size="small"
            title={t("Value Search Filter Form", { value: t("Product") })}
          >
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="categoryId"
                    label={t("Category")}
                    initialValue={categoryId}
                    rules={[
                      {
                        required: true,
                        message: t("Please select value!", {
                          value: t("Category"),
                        }),
                      },
                    ]}
                  >
                    <Select
                      loading={loadingSearchListCategories}
                      options={parentCategoryIdAndSubCategoriesMap
                        .get(null)
                        .map((parentCategory) => {
                          return {
                            label: parentCategory.name,
                            options: parentCategoryIdAndSubCategoriesMap
                              .get(parentCategory.id)
                              .map((subCategory) => ({
                                label: subCategory.name,
                                value: subCategory.id,
                              })),
                          };
                        })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="productName"
                    label={t("Name")}
                    initialValue={name}
                    rules={[
                      {
                        max: 40,
                        message: t("Maximum value characters!", { value: 40 }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="statusList"
                    label={t("Status")}
                    initialValue={statusList}
                    rules={[
                      {
                        required: true,
                        message: t("Please select value!", {
                          value: t("Status"),
                        }),
                      },
                    ]}
                  >
                    <Select mode="multiple">
                      <Select.Option value="ACTIVE">
                        {t("Active")}
                      </Select.Option>
                      <Select.Option value="PASSIVE">
                        {t("Passive")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item style={{ marginBottom: 8 }}>
                    <Row gutter={16}>
                      <Col style={{ flexGrow: 1 }}></Col>
                      <Col>
                        <Button
                          disabled={
                            loadingSearchListCategories ||
                            loadingSearchPageProducts
                          }
                          onClick={() => {
                            form.setFieldValue("categoryId", undefined);
                            form.setFieldValue("productName", undefined);
                            form.setFieldValue("statusList", [
                              "ACTIVE",
                              "PASSIVE",
                            ]);
                          }}
                        >
                          {t("Reset")}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={loadingSearchListCategories}
                          loading={loadingSearchPageProducts}
                        >
                          {t("Filter")}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24}>
          <Table
            scroll={{ x: "100%" }}
            bordered
            columns={columns}
            dataSource={productPage.content}
            pagination={{
              showSizeChanger: true,
              current: productPage.number + 1,
              pageSize: productPage.size,
              total: productPage.totalElements,
            }}
            rowKey="id"
            onChange={(pagination, filters, sorter) => {
              searchParams.set("page", pagination.current);
              searchParams.set("size", pagination.pageSize);
              setSearchParams(searchParams);
            }}
            loading={loadingSearchPageProducts}
          />
        </Col>
      </Row>
    )
  );
};

export default ProductListPage;
